<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <template v-for="(tab, i) in tabs">
        <v-tab
          class="primary--text"
          @click="way = tab.way"
          v-if="checktype(tab.way)"
          :key="i + 'tab'"
        >
          <img :src="tab.img" :width="tab.width" />
          &nbsp; &nbsp;{{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>
    <Profile v-show="way == 'profile'" />
    <Repin v-show="way == 'repin'" />
    <ReTpin v-show="way == 'retpin'" />
    <Configure v-show="way == 'design'" />
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Profile from "../setting/Profile.vue";
import Repin from "../setting/Repin.vue";
import ReTpin from "../setting/ReTpin.vue";
import Configure from "../setting/Configure.vue";
export default {
  components: {
    Profile,
    Repin,
    ReTpin,
    Progress,
    Preview,
    Message,
    Configure
  },
  data() {
    return {
      way: "profile",
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      type: "SS",
      tabs: [
        {
          name: "My Profile",
          img: require("../assets/user.png"),
          way: "profile",
          width: 25
        },
        {
          name: "Change PIN",
          img: require("../assets/changepassword.png"),
          way: "repin",
          width: 25
        },
        {
          name: "Get TPIN",
          img: require("../assets/changepassword.png"),
          way: "retpin",
          width: 25
        },
        // {
        //   name: "Configure",
        //   img: require("../assets/setting_icon.png"),
        //   way: "design",
        //   width: 25
        // }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    dispatch() {
      let vm = this;
      if (vm.user.length != 0) {
        let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
        vm.$store.dispatch("axiosget", {
          url: "GetUserInfo",
          data: doc,
          way: "setprofile"
        });
        vm.$store.dispatch("axiosget", {
          url: "statelist",
          data: doc,
          way: "setstatelist"
        });
      }
    },
    checktype(way) {
      let vm = this;
      if (vm.user.Type == "Dealer" && way == "downline") return false;
      return true;
    }
  },
  mounted() {
    let vm = this;
    if (vm.user.length == 0) {
      vm.$router.push("/account");
    }
    vm.dispatch();
    if (vm.user.Type == "Dealer") vm.check = false;
  }
};
</script>
