<template>
  <div>
    <!-- <v-tabs
      next-icon="arrow_forward_ios"
      slider-color="primary"
      v-model="active"
      light
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="(tab, i) in tabs"
        @click="way = tab.way"
        :key="i + 'tab'"
        class="primary--text"
      >
        <img :src="tab.img" :width="25" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs> -->
    <div
      class="main pt-3"
      :style="{ flexDirection: position ? 'column' : 'row' }"
    >
      <div class="left pb-3" :style="{ width: position ? '100%' : '350px' }">
        <div class="left__content">
          <div class="form">
            <IMPS v-show="way == 'imps'"  @data-changed="handleDataChange" @refresh ="refresh"  :saccountDetail="saccountDetail" />
            <Prepaid v-show="way == 'prepaid'" />
            <Postpaid v-show="way == 'postpaid'" />
            <Gas v-show="way == 'gas'" />
            <Electricity v-show="way == 'electricity'" />
            <DTHBooking v-show="way == 'dthbooking'" />
            <Broadband v-show="way == 'broadband'" />
            <Water v-show="way == 'water'" />
            <Dish v-show="way == 'dish'" />
            <Transfer v-show="way == 'transfer'" />
          </div>
        </div>
      </div>
      <div class="right" :class="position ? '' : 'pl-3'">
        <v-card>
          <v-toolbar dense dark class="secondary">
            <div v-show="change == true">Last 25 Transaction</div>
            <div v-show="change == false">Today Recharge</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              dense
              class="pt-0 mr-3"
              color="white"
              append-icon="search"
              placeholder="Search"
              hide-details
            ></v-text-field>
            <v-toolbar-items>
              <download-excel
                :data="table"
                v-show="change"
                name="Last25_Recharge.xls"
                :fields="fields"
                style="height: 30px; margin-top: 9px; margin-right: 10px"
              >
                <v-btn class="success" small>Export</v-btn>
              </download-excel>
              <download-excel
                :data="rtable"
                v-show="!change"
                name="Today_Recharge.xls"
                :fields="fields"
                style="height: 30px; margin-top: 9px; margin-right: 10px"
              >
                <v-btn class="success" small>Export</v-btn>
              </download-excel>
              <v-btn
                @click="change = false"
                v-show="change"
                style="height: 30px"
                class="success"
                >Today</v-btn
              >
              <v-btn
                v-show="!change"
                @click="change = true"
                style="height: 30px"
                class="success"
                >Last 25</v-btn
              >
              <v-btn @click="refresh" icon>
                <v-icon style="margin-top: 0px" color="white">refresh</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="table"
            hide-actions
            :search="search"
            :custom-sort="customSort"
            :loading="loading"
            class="customtable"
            v-if="change"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td :style="{ background: getbackground(props.item.status) }">
                {{ props.item.orderno }}
              </td>
              <td :style="{ background: getbackground(props.item.status) }">
                {{ props.item.service }}
              </td>
              <td :style="{ background: getbackground(props.item.status) }">
                {{ props.item.mobile }}
              </td>
              <td :style="{ background: getbackground(props.item.status) }">
                {{ props.item.amount }}
              </td>
              <td :style="{ background: getbackground(props.item.status) }">
                <v-btn
                  small
                  flat
                  :class="checkstatus(props.item.status)"
                  style="width: 100%"
                  >{{ props.item.status }}</v-btn
                >
              </td>
              <td :style="{ background: getbackground(props.item.status) }">{{ props.item.recipient_name }}</td>
              <!-- <td :style="{ background: getbackground(props.item.status) }">{{ props.item.time }}</td> -->
              <td :style="{ background: getbackground(props.item.status) }">
            {{
              new Date(parseInt(props.item.time))
                | moment("DD-MM-YYYY, h:mm:ss a")
            }}
          </td>
              <td :style="{ background: getbackground(props.item.status) }">{{ props.item.bankName }}</td>
              <td :style="{ background: getbackground(props.item.status) }">{{ props.item.BankRefrenceNo }}</td>
              <td :style="{ background: getbackground(props.item.status) }">
                {{ props.item.tid }}
              </td>
              <!-- <td :style="{ background: getbackground(props.item.status) }">{{ props.item.type }}</td>
              <td
                :style="{ background: getbackground(props.item.status) }"
              >{{ props.item.openbalance }}</td>
              <td
                :style="{ background: getbackground(props.item.status) }"
              >{{ props.item.closebalance }}</td>
              <td :style="{ background: getbackground(props.item.status) }">{{ props.item.com }}</td>
              <td
                :style="{ background: getbackground(props.item.status) }"
              >{{ props.item.surcharge }}</td>
              <td :style="{ background: getbackground(props.item.status) }">
                {{
                new Date(parseInt(props.item.time))
                | moment("DD-MM-YYYY, h:mm:ss a")
                }}
              </td> -->
              <td :style="{ background: getbackground(props.item.status) }">
                <v-btn
                  small
                  style="width: 100%"
                  class="primary"
                  @click="print(props.item)"
                  >Print</v-btn
                >
              </td>
              <td :style="{ background: getbackground(props.item.status) }">
                <v-btn
                  small
                  style="width: 100%"
                  v-if="change && props.item.status.toLowerCase() != 'fail'"
                  @click="openremark(props.item)"
                  class="warning"
                  >Complaint</v-btn
                >
              </td>
            </template>
          </v-data-table>
          <v-data-table
            :headers="headers"
            :items="rtable"
            hide-actions
            :custom-sort="customSort"
            :search="search"
            :loading="loading"
            class="customtable"
            v-else
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.orderno }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.service }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.mobile }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.amount }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                <v-btn
                  small
                  :class="checkrstatus(props.item.status)"
                  style="width: 100%"
                  flat
                  >{{ checkrbtn(props.item.status) }}</v-btn
                >
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.tid }}
              </td>
              <!-- <td :style="{ background: getrbackground(props.item.status) }">{{ props.item.type }}</td>
              <td
                :style="{ background: getrbackground(props.item.status) }"
              >{{ props.item.openbalance }}</td>
              <td
                :style="{ background: getrbackground(props.item.status) }"
              >{{ props.item.closebalance }}</td>
              <td :style="{ background: getrbackground(props.item.status) }">{{ props.item.com }}</td>
              <td
                :style="{ background: getrbackground(props.item.status) }"
              >{{ props.item.surcharge }}</td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{
                new Date(parseInt(props.item.time))
                | moment("DD-MM-YYYY, h:mm:ss a")
                }}
              </td> -->
              <td :style="{ background: getrbackground(props.item.status) }">
                <v-btn
                  small
                  style="width: 100%"
                  class="primary"
                  @click="print(props.item)"
                  >Print</v-btn
                >
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                <v-btn
                  small
                  style="width: 100%"
                  v-if="change && props.item.status.toLowerCase() != 'fail'"
                  @click="openremark(props.item)"
                  class="warning"
                  >Complaint</v-btn
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <div
      class="main pt-3"
      :style="{ flexDirection: position ? 'column' : 'row' }"
    >
    <div class="right" :class="position ? '' : 'pl-3'">
        <v-card>           
          <v-data-table
            :headers="ASearchheaders"
            :items="ASearchtable"
            hide-actions
            :custom-sort="customSort"
            :search="search"
            :loading="loading"
            class="customtable"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.Accountno }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.AccountName }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.Ifsc }}
              </td>
              <td :style="{ background: getrbackground(props.item.status) }">
                {{ props.item.BankName }}
              </td>              
              <td :style="{ background: getrbackground(props.item.status) }">
                <v-btn
                  small
                  style="width: 100%"
                  class="primary"
                  @click="PassSearchAccountDetail(props.item)"
                  >Select</v-btn
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </div>
  </div>
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" :sync="sync" />
    <Message v-if="message" :res="response" />
    <Complaint v-if="complaint" :id="tid" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Prepaid from "../billpay/Prepaid.vue";
import Postpaid from "../billpay/Postpaid.vue";
import Gas from "../billpay/Gas.vue";
import Electricity from "../billpay/Electricity.vue";
import DTHBooking from "../billpay/DTHBooking.vue";
import Dish from "../billpay/Dish.vue";
import Transfer from "../billpay/Transfer.vue";
import Complaint from "../components/Complaint.vue";
import Broadband from "../billpay/Broadband.vue";
import Water from "../billpay/Water.vue";
import IMPS from "../billpay/IMPS.vue";

export default {
  components: {
    Prepaid,
    Postpaid,
    Gas,
    Electricity,
    DTHBooking,
    Dish,
    Progress,
    Preview,
    Message,
    Transfer,
    Complaint,
    Broadband,
    Water,IMPS
  },
  data() {
    return {
      way: "imps",
      loading: false,
      active: 0,
      data: [],
      sync: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      change: true,
      params: "",
      search: "",
      type: "BP",
      tid: "",
      pagination: {},
      complaint: false,
      saccountNumber:"",
      saccountDetail:{
        AccountName:"",
        Accountno:"",
        BankName:"",
        Ifsc:""
      },
      tabs: [
      // {
      //     name: "IMPS",
      //     img: require("../assets/phone.png"),
      //     way: "imps",
      //   },
        // {
        //   name: "Prepaid",
        //   img: require("../assets/phone.png"),
        //   way: "prepaid",
        // },
        // { name: "DTH", img: require("../assets/dish.png"), way: "dish" },
        // {
        //   name: "Post Paid",
        //   img: require("../assets/phone.png"),
        //   way: "postpaid"
        // },
        // { name: "Gas", img: require("../assets/gas.png"), way: "gas" },
        // {
        //   name: "Electricity",
        //   img: require("../assets/electricity.png"),
        //   way: "electricity"
        // },
        // {
        //   name: "BroadBand",
        //   img: require("../assets/broadband.png"),
        //   way: "broadband"
        // },
        // {
        //   name: "Water",
        //   img: require("../assets/water.png"),
        //   way: "water"
        // },
        // {
        //   name: "Dth Booking",
        //   img: require("../assets/dish.png"),
        //   way: "dthbooking"
        // },
         {
          name: "Fund Transfer",
          img: require("../assets/transfer.png"),
          way: "transfer"
        }
      ],
      fields: {
        "Recharge ID": "orderno",
        Service: "service",
        MobileNo: "mobile",
        Amount: "amount",
        Status: "status",
        "Transaction ID": "tid",
        Type: "type",
        "Opening Balance": "openbalance",
        "Closing Balance": "closebalance",
        Commission: "com",
        Surcharge: "surcharge",
        "Recharge Time": "time",
        Reason:"Reason",
        
      },
      headers: [
        { text: "Transaction ID", value: "orderno" },
        { text: "TxnMode", value: "service" },
        { text: "AccountNumber", value: "mobile" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Recipient Name", value: "recipient_name" },
        { text: "time", value: "time" },
        { text: "BankName", value: "bankName" },
        { text: "BankRefrenceNo", value: "BankRefrenceNo" },
        { text: "Transaction ID", value: "tid" },
        // { text: "Type", value: "type" },
        // { text: "Opening Balance", value: "openbalance" },
        // { text: "Closing Balance", value: "closebalance" },
        // { text: "Commission", value: "com" },
        // { text: "Surcharge", value: "surcharge" },
        // { text: "Recharge Time", value: "time" },
        { text: "Print", value: "action", align: "center" },
        { text: "Complain", value: "action", align: "center" },
      ],
      ASearchheaders: [
        { text: "Accountno", value: "Accountno" },
        { text: "AccountName", value: "AccountName" },
        { text: "Ifsc", value: "Ifsc" },
        { text: "BankName", value: "BankName" },      
        { text: "Select", value: "action", align: "center" },       
      ],

      table: [],
      shifttable: [],
      rtable: [],
      ASearchtable:[],
    };
  },
  methods: {

    handleDataChange(data) {
      this.saccountNumber = data;
    
      this.fetchAccountHis();
      
      
    },
    checkstatus(status) {
      if (this.change) {
        status = status.toLowerCase();
        if (status == "success") return "success";
        if (status == "pending") return "grey";
        if (status == "in process") return "warning";
        if (status == "fail") return "error";
      }
      return "primary";
    },
    checkrstatus(status) {
      if (status == 2) return "success";
      if (status == 0) return "grey";
      if (status == 3) return "warning";
      if (status == 1) return "error";
      return "primary";
    },
    checkrbtn(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return "Unknown";
    },
    getbackground(status) {
      if (this.change) {
        status = status.toLowerCase();
        if (status == "success") return "#ffff";
        if (status == "pending") return "#B0BEC5";
        if (status == "in process") return "#FFF59D";
        if (status == "fail") return "#EF9A9A";
      }
      return "#90CAF9";
    },
    getrbackground(status) {
      if (status == 2) return "#ffff";
      if (status == 0) return "#B0BEC5";
      if (status == 3) return "#FFF59D";
      if (status == 1) return "#EF9A9A";
      return "#90CAF9";
    },
    refresh() {
      let vm = this;
      if (vm.change) {
        vm.fetchdata();
      } else {
        vm.fetchrecharge();
      }
    },
    even(arr) {
      return arr.slice().sort(function (a, b) {
        return a.orderno - b.orderno;
      });
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === "orderno") {
          if (isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    openremark(item) {
      let vm = this;
      vm.tid = item.orderno;
      if (vm.tid == null) return;
      vm.complaint = true;
    },
    handleresize() {
      let vm = this;
      let width = window.innerWidth - 330 - 293 + "px";
      vm.$store.commit("setrwidth", width);
    },
    fetchdata() {
      let vm = this;
      if (vm.user.length != 0) {
        let ldoc = {
          PinNo: vm.user.pin,
          MobileNo: vm.user.phone,
          PageSize: 25,
        };
        let url =
          vm.$root.loginType === "dmr"
            ? "LastTransactionDMR"
            : "LastTransaction";
        vm.$store.dispatch("axiosget", {
          url: url,
          data: ldoc,
          way: "setlasttrans",
        });
      }
    },

    fetchAccountHis() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        AccountNumber:this.saccountNumber,
      };
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "RNPSearchPayoutDetail",
          type: "dmrRNP",
          data: doc,
        })
        .then(function (res) {      
          vm.ShowASearchtable(res.data.Data);
        })
        .catch(function (err) {
          vm.notification("error", err.message);
        });
    },

    showtable() {
      let vm = this;
      vm.table = [];
      if (vm.report == undefined) return;
      for (let i = 0; i < vm.report.length; i++) {
        let doc = vm.report[i];
        let data = {
          orderno: doc.RechargeId,
          service: doc.ServiceName,
          mobile: doc.MobileNo,
          status: doc.Status,
          type: doc.RechargeType,
          com: doc.Commission,
          tid: doc.OperatorId,
          amount: doc.Amount,
          openbalance: doc.OpeningBal,
          closebalance: doc.ClosingBal,
          time: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          surcharge: doc.PersonalSurcharge,
          recipient_name:doc.recipient_name,
        accountno:doc.accountno,
        bankName:doc.bankName,
        BankRefrenceNo:doc.BankRefrenceNo,
        Reason : doc.Reason,
        RechargeId : doc.RechargeId,
          
        };
        vm.table.push(data);
      }
      // vm.table = vm.even(vm.table)
      vm.loading = false;
    },

    ShowASearchtable(datas){
      let vm = this;
      vm.ASearchtable = [];
      for (let i = 0; i < datas.length; i++) {
        let doc = datas[i];
        let data = {
          Accountno: doc.Accountno,
          Ifsc: doc.Ifsc,
          BankName: doc.BankName,
          AccountName: doc.AccountName,          
        };
        vm.ASearchtable.push(data);
      }
      vm.loading = false;
    },

    showrtable(datas) {
      let vm = this;
      vm.rtable = [];
      for (let i = 0; i < datas.length; i++) {
        let doc = datas[i];
        let data = {
          orderno: doc.Id,
          service: doc.ServiceName,
          mobile: doc.MobileNo,
          status: doc.Status,
          type: doc.RechargeType,
          com: doc.Commission,
          tid: doc.TransactionId,
          amount: doc.Amount,
          openbalance: doc.OpeningBal,
          closebalance: doc.ClosingBal,
          time: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          surcharge: doc.PSurcharge,
          Reason : doc.Reason,
          RechargeId : doc.RechargeId,
        };
        vm.rtable.push(data);
      }
      vm.loading = false;
    },
    fetchrecharge() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: date,
        EndDate: date,
      };
      let url =
        vm.$root.loginType === "dmr" ? "RechargeSearchDMR" : "RechargeSearch";

      vm.$store
        .dispatch("axios", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.loading = false;
          vm.showrtable(res.data.Data);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    dispatch() {
      let vm = this;
      if (vm.user.length != 0) {
        let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
        let ldoc = {
          PinNo: vm.user.pin,
          MobileNo: vm.user.phone,
          PageSize: 25,
        };
        let url =
          vm.$root.loginType === "dmr"
            ? "LastTransactionDMR"
            : "LastTransaction";
        vm.$store.dispatch("axiosget", {
          url: url,
          data: ldoc,
          way: "setlasttrans",
        });
        vm.$store.dispatch("operator", { data: doc });
        vm.$store.dispatch("getbalance");
      }
    },
    PassSearchAccountDetail(item) {
      debugger;
this.saccountDetail.AccountName = item.AccountName;
this.saccountDetail.Accountno = item.Accountno;
this.saccountDetail.Ifsc = item.Ifsc;
this.saccountDetail.BankName = item.BankName;
console.log(this.saccountDetail );
    },
    print(item) {
      debugger;
      let vm = this;
      var status = item.status;
      if (!vm.change) {
        status = vm.checkrbtn(item.status);
      }
      var date = vm.$moment(parseInt(item.time)).format("DD-MM-YYYY, h:mm a");
      var tid = item.tid;
      if (tid == null && tid == undefined) {
        tid = "-";
      }
      let splitArray = item.Reason.split(',');        
      let resdetail =  splitArray[0];
      let Acresdetail =  resdetail.split(':');    
      let Acountresdetail = Acresdetail[1].split('-'); 
      let Name =   Acountresdetail[0].split('$').join(' ');
      let ifsc =   Acountresdetail[2];
      let bankname =    Acountresdetail[3].split('$').join(' ');
  
      var mywindow = window.open("");
      var head =
        "<html><head><title>Print</title><style>.mainwidth{width:400px;min-height:650px;background:white;margin:0 auto;border-radius:5px;}.header{width:398px;margin:1px;float:left;height:30px;border:0px;background:transparent;font-size:1em;background:lightseagreen;color:white;}.table{padding:10px;width:100%;}.table tr td{padding-bottom:5px;padding-top:5px;border-bottom:1px solid lightgrey;}</style>";
      mywindow.document.write(head);
      mywindow.document.write("</head><body >");
      mywindow.document.write(
         '<body><div style="border:1px solid lightgrey" class="mainwidth"><button class="header">Transaction Receipt</button>'
      );
      mywindow.document.write('<table class="table">');
      mywindow.document.write(
        "<tr><td>Transaction ID</td><td>" + item.RechargeId + "</td></tr>"
      );
      mywindow.document.write("<tr><td>RRN Number</td><td>" + tid + "</td></tr>");
      mywindow.document.write("<tr><td>Benificiary Name</td><td>" + Name + "</td></tr>");
      mywindow.document.write("<tr><td>Bank Name</td><td>" + bankname + "</td></tr>");
      mywindow.document.write("<tr><td>IFSC Code</td><td>" + ifsc + "</td></tr>");
      mywindow.document.write("<tr><td>Account Number</td><td>" +  item.mobile + "</td></tr>");
      mywindow.document.write(
        "<tr><td>Transaction Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Service Charge</td><td>" + item.surcharge + "</td></tr>"
      );
      mywindow.document.write(
        "<tr><td>Total Amount</td><td>" + item.amount + "</td></tr>"
      );
      mywindow.document.write("<tr><td>Date</td><td>" + date + "</td></tr>");
      // mywindow.document.write(
      //   "<tr><td>Dealer Name</td><td>" + vm.user.UserName + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Operator Name</td><td>" + item.service + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Reference No</td><td>" + item.orderno + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Amount</td><td>" + item.amount + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Mobile No</td><td>" + item.mobile + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Status</td><td>" + status + "</td></tr>"
      // );
      // mywindow.document.write("</table>");
      // mywindow.document.write(
      //   '<button class="header">Retailer Information</button>'
      // );
      // mywindow.document.write('<table class="table">');
      // mywindow.document.write(
      //   "<tr><td>Name</td><td>" +
      //     vm.getprofile.FirstName +
      //     " " +
      //     vm.getprofile.LastName +
      //     "</td></tr>"
      // );
      // mywindow.document.write(
      //   '<tr><td style="width:100px">Mobile No</td><td>' +
      //     vm.getprofile.MobileNo +
      //     "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Email ID</td><td> " + vm.getprofile.Email + "</td></tr>"
      // );
      // mywindow.document.write(
      //   "<tr><td>Address</td><td>" + vm.getprofile.Address + "</td></tr>"
      // );
      // mywindow.document.write("</table>");
      mywindow.document.write("</div>");
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      setTimeout(print, 1000);
      function print() {
        mywindow.print();
      }
    },
  },
  computed: {
    position() {
      let style = localStorage.position;
      if (style == "true") {
        return true;
      }
      return false;
    },
    getprofile() {
      return this.$store.getters.profile.Data;
    },
    report() {
      return this.$store.getters.lasttrans.Data;
    },
    user() {
      return this.$store.getters.user;
    },
    items() {
      return this.$store.getters.quickpay;
    },
    logo() {
      return this.$store.getters.logo;
    },
    details() {
      return this.$store.getters.details;
    },
  },
  watch: {
    report() {
      this.loading = true;
      this.showtable();
    },
    change() {
      if (!this.change) {
        this.fetchrecharge();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.dispatch();
    if (vm.user.Type == "Admin") return vm.$router.push("/transaction");
    if (vm.user.Type == "MasterDistributor")
      return vm.$router.push("/transaction");
    if (vm.user.Type == "Distributor") return vm.$router.push("/transaction");
    vm.fetchrecharge();
    this.showtable();
    let height = window.innerHeight - 400;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  },
};
</script>
<style>
.customtable .v-table__overflow {
  overflow-y: hidden !important;
}
</style>
<style scoped>
.main {
  width: 100%;
  display: flex;
  height: 100%;
}
.left {
  width: 350px;
}
.left__content {
  margin: 0 auto;
  width: 350px;
}
.form {
  float: left;
  padding: 15px;
  background: white;
  width: 100%;
}
.right {
  flex-grow: 1;
  max-height: calc(100vh - 200px) !important;
  overflow: hidden;
}
</style>
