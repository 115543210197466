<template>
  <div>
   
    <v-autocomplete
      v-model="operator"
      :items="sortedproviver"
      label="Select Operator"
      style="margin: 0 auto; height: 60px"
      chips
      item-text="name"
      item-value="name"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close class="chip--select-multi">
          <v-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile v-html="data.item.name"></v-list-tile>
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>

    <v-text-field
      v-model="rechargernumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Account Number"
    />
    <v-btn
      small
      class="primary"
      depressed
      round
      style="
        float: right;
        font-size: 0.8em;
        padding-left: 15px;
        padding-right: 15px;
      "
      @click="fetchoffer" :disabled="isvButtonDisabled"
      >Fetch Billinfo</v-btn>
     <br />
      <br />
      <p>Bill date is: {{ billdate }}</p>
      <p>Due date is: {{ duedate }}</p>
   
    <v-text-field
      type="text"
      v-model="name"
      style="margin: 0 auto; height: 60px"
      label="Enter Customer Name"
    />

    <v-text-field
      v-model="whatsapp"
      style="margin: 0 auto; height: 60px"
      label="Enter Whatsapp Number"
    />
    <v-text-field
      v-model="rechargeramount"
      style="margin: 0 auto; height: 60px"
      label="Enter Recharge Amount"
    />

    <div style="width: 100%; float: left">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
        >Submit</v-btn
      >
    </div>

    <v-dialog v-model="isBillInfo" persistent width="400">
      <v-card>
        <v-card-title>Bill Info</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <div>Name: {{ this.name }}</div>
           <div>BillDate :{{ this.billdate }}</div>
            <div>DueDate: {{ this.duedate }}</div>               
            <div>Amount: {{ this.rechargeramount }}</div>
          </div>
        </v-card-title>

        <v-card-actions>
         
          <v-spacer></v-spacer>
          <v-btn flat color="error" @click="
            isBillInfo = false;       
       
          ">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operator: "",
      provider: [],
      name: "",
      whatsapp: "",
      rechargernumber: "",
      rechargeramount: "",
      duedate:"",
      billdate:"",
      isvButtonDisabled:false,
      isBillInfo:false,
    };
  },
  computed: {
    getprovider() {
      return this.$store.getters.operator;
    },
    rwidth() {
      return this.$store.getters.rwidth;
    },
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    sortedproviver() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      let provider = this.provider;
      return provider.sort(compare);
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.rechargernumber = "";
      vm.rechargeramount = "";
      vm.whatsapp = "";
      vm.name = "";
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    pushoperator() {
      let vm = this;
      vm.provider = [];
      let path = this.$store.getters.path;
      for (let i = 0; i < vm.getprovider.length; i++) {
        let doc = vm.getprovider[i];
        console.log(doc.Category);
        if (doc.Category == "ElectricityBill") {
          let data = {
            avatar: path + doc.CircleImg,
            name: doc.DisplayServiceName,
          };
          vm.provider.push(data);
        }
      }
    },
    fetchoffer() {

      let vm = this;
      var Servicecode ="NA";
      var param2 = "";

      if (vm.operator === "MadhyaGujaratVijCompanyLimited") {
                Servicecode = "MadhyaGujaratVijCompanyLimited";
            }
            else if (vm.operator === "PaschimGujaratVijCompanyLimited") { Servicecode = "PaschimGujaratVijCompanyLimited"; }
            else if (vm.operator === "DakshinGujaratVijCompanyLimited") { Servicecode = "DakshinGujaratVijCompanyLimited"; }
            else if (vm.operator === "UttarGujaratVijCompanyLimited") { Servicecode = "UttarGujaratVijCompanyLimited"; }
            else if (vm.operator === "DGVCL_Fetch&pay") { Servicecode = "DakshinGujaratVijCompanyLimited(DGVCL)_Fetchandpay"; }
            else if (vm.operator === "MGVCL_Fetch&pay") { Servicecode = "MadhyaGujaratVijCompanyLimited(MGVCL)_Fetchandpay"; }
            else if (vm.operator === "UGVCL_Fetch&pay") { Servicecode = "UttarGujaratVijCompanyLimited(UGVCL)_Fetchandpay"; }
            else if (vm.operator === "PGVCL_Fetch&pay") { Servicecode = "PaschimGujaratVijCompanyLimited(PGVCL)_Fetchandpay"; }
            else if (vm.operator === "Torrent Power - Ahmedabad") { Servicecode = "TorrentPower";param2="Ahmedabad"; }
            else if (vm.operator === "Torrent Power - Surat") { Servicecode = "TorrentPower"; param2="Surat";}
            

     
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.circle == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Circle Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Mobile Number Required",
          type: "error",
        });
     
     

        this.isvButtonDisabled = true;
      debugger;
    

      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        servicename: Servicecode,
        paramvalue1: vm.rechargernumber.trim(),
        paramvalue2: param2,
        
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Electricityfetchbillrequest",
          type: "BBPSRNP",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.code === "RP000") {
                     debugger; 
                     vm.rechargeramount =  data.data.amount;
          vm.name =  data.data.accountHolderName;
          vm.duedate = data.data.dueDate;
          vm.billdate = data.data.billDate;
          vm.isBillInfo = true;
           
          }
          else if(data.code === "RP002") {
            debugger;
            //vm.notification("error", data.Message);
            vm.$root.alert(data.data.error.reason);         
          }
          else
          {
            debugger;
            vm.$root.alert(data.data.status);
          }
        })
        .catch(function (err) {
          debugger;
          vm.loader = false;
          //vm.notification("error", err.message);
          vm.$root.alert(err.message);
         
        }).finally(() => {
          // Re-enable the button after the operation completes or fails
          this.isvButtonDisabled = false;
        });
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.name == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Customer Name Required",
          type: "error",
        });
      if (vm.whatsapp == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Whatsapp Number Required",
          type: "error",
        });
      if (vm.whatsapp.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.rechargeramount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Amount Required",
          type: "error",
        });


        var Servicecode ="NA";
      var param2 = "";

      if (vm.operator === "MadhyaGujaratVijCompanyLimited") {
                Servicecode = "MadhyaGujaratVijCompanyLimited";
            }
            else if (vm.operator === "PaschimGujaratVijCompanyLimited") { Servicecode = "PaschimGujaratVijCompanyLimited"; }
            else if (vm.operator === "DakshinGujaratVijCompanyLimited") { Servicecode = "DakshinGujaratVijCompanyLimited"; }
            else if (vm.operator === "UttarGujaratVijCompanyLimited") { Servicecode = "UttarGujaratVijCompanyLimited"; }
            else if (vm.operator === "DGVCL_Fetch&pay") { Servicecode = "DakshinGujaratVijCompanyLimited(DGVCL)_Fetchandpay"; }
            else if (vm.operator === "MGVCL_Fetch&pay") { Servicecode = "MadhyaGujaratVijCompanyLimited(MGVCL)_Fetchandpay"; }
            else if (vm.operator === "UGVCL_Fetch&pay") { Servicecode = "UttarGujaratVijCompanyLimited(UGVCL)_Fetchandpay"; }
            else if (vm.operator === "PGVCL_Fetch&pay") { Servicecode = "PaschimGujaratVijCompanyLimited(PGVCL)_Fetchandpay"; }
            else if (vm.operator === "Torrent Power - Ahmedabad") { Servicecode = "TorrentPower";param2="Ahmedabad"; }
            else if (vm.operator === "Torrent Power - Surat") { Servicecode = "TorrentPower"; param2="Surat";}

            var CName =  vm.name.split(' ').join('_');
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=BP+" +
        Servicecode +
        "+" +
        vm.rechargernumber +
        "-" + param2 +"-"+
        CName +
        "-" +
        vm.whatsapp +
        "+" +
        vm.rechargeramount +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        Operator: vm.operator,
        "Account Number": vm.rechargernumber,
        Name: vm.name,
        "Whatsapp Number": vm.whatsapp,
        "Recharge Amount": vm.rechargeramount,
      };
      vm.$parent.sync = {
        title: vm.operator,
        subtitle: vm.rechargernumber,
        status: "process",
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprovider.length != 0) {
      vm.pushoperator();
    }
  },
};
</script>
