<template>
  <div class="footer_wrapper">
    <div class="footer">
      <p class="headlineLink">Company Policy Link.</p>
      <br /> <br />
      <a href="javascript:void(0);" @click="
        transfer = true;">Terms & Conditions</a> <br />
      <a href="javascript:void(0);" @click="
        PrivacyPolicy = true;">Privacy Policy</a> <br />
      <a href="javascript:void(0);" @click="
        NonRefundORNonCancellationPolicy = true;">Return, Refund, &
        Cancellation Policy</a> <br />
      <p class="headline">© {{ details.ContactName }} All Rights Reserved.</p>


    </div>
    <v-dialog v-model="transfer" persistent width="1300">
      <v-card>
        <v-card-title>Terms & Conditions</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Terms & Conditions</b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The terms "We" / "Us" / "Our"/”Company” individually and collectively refer to Payone Business and the terms
            "Visitor” ”User” refer to the users.This page states the Terms and Conditions under which you (Visitor) may
            visit this website www.admin.payinpayout.co.in Please read this page carefully. If you do not accept the Terms
            and Conditions stated here, we would request you to exit this site. The business, any of its business
            divisions and / or its subsidiaries, associate companies or subsidiaries to subsidiaries or such other
            investment companies (in India or abroad) reserve their respective rights to revise these Terms and Conditions
            at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the
            Terms and Conditions, because they are binding on all users of this Website.
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> USE OF CONTENT</b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof,
            appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the
            business and / or its associate entities who feature on this Website. The use of these properties or any other
            content on this site, except as provided in these terms and conditions or in the site content, is strictly
            prohibited.You may not sell or modify the content of this Website or reproduce, display, publicly perform,
            distribute, or otherwise use the materials in any way for any public or commercial purpose without the
            respective organisation’s or entity’s written permission.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> ACCEPTABLE WEBSITE USE
              <br />
              Security Rules</b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Visitors are prohibited from violating or attempting to violate the security of the Web site, including,
            without limitation, <br />(1) accessing data not intended for such user or logging into a server or account
            which
            the user is not authorised to access,<br /> (2) attempting to probe, scan or test the vulnerability of a
            system or
            network or to breach security or authentication measures without proper authorisation, <br />(3) attempting to
            interfere with service to any user, host or network, including, without limitation, via means of submitting a
            virus or "Trojan horse" to the Website, overloading, "flooding", "mail bombing" or "crashing", or<br /> (4)
            sending
            unsolicited electronic mail, including promotions and/or advertising of products or services. Violations of
            system or network security may result in civil or criminal liability. The business and / or its associate
            entities will have the right to investigate occurrences that they suspect as involving such violations and
            will have the right to involve, and cooperate with, law enforcement authorities in prosecuting users who are
            involved in such violations.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> General Rules
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Visitors may not use the Web Site in order to transmit, distribute, store or destroy material (a) that could
            constitute or encourage conduct that would be considered a criminal offence or violate any applicable law or
            regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual
            property rights of others or violate the privacy or publicity of other personal rights of others, or (c) that
            is libellous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> INDEMNITY
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The User unilaterally agree to indemnify and hold harmless, without objection, the Company, its officers,
            directors, employees and agents from and against any claims, actions and/or demands and/or liabilities and/or
            losses and/or damages whatsoever arising from or resulting from their use of www.admin.payinpayout.co.in or
            their breach of the terms .
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> LIABILITY
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            User agrees that neither Company nor its group companies, directors, officers or employee shall be liable for
            any direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages,
            resulting from the use or/and the inability to use the service or/and for cost of procurement of substitute
            goods or/and services or resulting from any goods or/and data or/and information or/and services purchased
            or/and obtained or/and messages received or/and transactions entered into through or/and from the service
            or/and resulting from unauthorized access to or/and alteration of user's transmissions or/and data or/and
            arising from any other matter relating to the service, including but not limited to, damages for loss of
            profits or/and use or/and data or other intangible, even if Company has been advised of the possibility of
            such damages.User further agrees that Company shall not be liable for any damages arising from interruption,
            suspension or termination of service, including but not limited to direct or/and indirect or/and incidental
            or/and special consequential or/and exemplary damages, whether such interruption or/and suspension or/and
            termination was justified or not, negligent or intentional, inadvertent or advertent.
          </p>
        </v-card-body>



        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> DISCLAIMER OF CONSEQUENTIAL DAMAGES
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            In no event shall Company or any parties, organizations or entities associated with the corporate brand name
            us or otherwise, mentioned at this Website be liable for any damages whatsoever (including, without
            limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of
            data information or business interruption) resulting from the use or inability to use the Website and the
            Website material, whether based on warranty, contract, tort, or any other legal theory, and whether or not,
            such organization or entities were advised of the possibility of such damages.
          </p>
        </v-card-body>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="error" @click="
            transfer = false;
          ">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="PrivacyPolicy" persistent width="1300">
      <v-card>
        <v-card-title>Privacy & Policy</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Introduction</b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Welcome to Payone Business-Mobile Recharge! This Privacy Policy outlines how we collect, use, and share
            personal and sensitive user data when you use our mobile application. By using our app, you consent to the
            practices described in this policy.
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Types of Personal and Sensitive User Data Collected</b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We collect the following types of personal and sensitive user data:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) Phone Number: We collect phone numbers to facilitate user registration, authentication, and
            communication.<br />
            2) Email Address: Email addresses are collected for account management, communication, and marketing purposes
            with user consent.<br />
            3) Images,video and Files: Our app may allow users to upload images and files for various purposes, such as
            sharing content with others or personalizing their experience within the app.<br />
            4) Image Information: Our app may collect and upload images uploaded by users for various purposes within the
            app.<br />
            5) Device IDs: We may collect unique device identifiers, including Advertising ID, IMEI, or other
            device-specific information, to enhance app functionality, analyze usage patterns, and improve user
            experience.<br />
            6) Contact Information: Name, email address, phone number.<br />
            7) Location Data: GPS location, IP address.<br />
            8) Contact List Information: Names, phone numbers, and other contact details.<br />
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Entity Reference
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            This privacy policy applies to Payone Business, developed and operated by Payone Business.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Clear Labeling
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            This document is labeled as the "Payone Business - Mobile Recharge Application Privacy Policy."
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> How We Use Your Information
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We use the collected information for various purposes, including but not limited to:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) Providing and improving the App's functionality. <br />
            2) Personalizing your experience within the App.
            <br />
            3) Communicating with you, responding to your requests, and providing support. <br />
            4) Analyzing usage patterns and trends to enhance our services.
            <br />
            5) Promoting the App and sending you updates, offers, and promotional materials.
            <br />
            6) Complying with legal obligations and protecting our rights.<br />
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Purposes for Data Access, Collection, Use, and Sharing
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We access, collect, use, and share user data for the following purposes:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) Account Registration and Authentication: Phone numbers and email addresses are collected for user
            registration and authentication to ensure secure access to our app.<br />
            2) Communication: Collected contact information is used to communicate with users, provide support, send
            notifications, and deliver relevant updates or announcements.<br />
            3) Content Management: Images and files uploaded by users are collected and stored to enable the intended
            functionality of the app, such as sharing content with other users or providing personalized services.<br />
            4) App Optimization and Analytics: Device IDs and other usage data are collected to analyze app performance,
            identify trends, and enhance user experience, features, and functionality.<br />
            5) App Functionality: Image information is collected to enable various features and functionalities within the
            app.<br />
          </p>
        </v-card-body>



        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Parties with Whom Data is Shared
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We may share user data with the following parties:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) Service Providers: We may engage third-party service providers, such as cloud hosting providers, analytics
            platforms, and communication tools, to assist us in delivering and improving our services.<br />
            2) Business Partners: In some cases, we may share user data with trusted business partners or affiliates for
            joint marketing efforts, collaborations, or other legitimate business purposes.<br />
            3) Legal Compliance: We may disclose user data when required to comply with applicable laws, regulations,
            legal processes, or governmental requests, enforce our policies, or protect our rights, property, or
            safety.<br />
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Data Sharing
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We may share your personal information with third parties in the following circumstances:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) With your consent.<br />
            2) To comply with legal requirements or protect our rights.<br />
            3) With service providers who assist us in delivering our services.<br />
            4) In connection with the sale, merger, or acquisition of all or part of our business.<br />
            5) Service Providers: For payment processing, analytics, and customer support.<br />
            6) Advertising Partners: For personalized advertising.<br />
            7) Legal Authorities: When required by law.<br />
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Use of Your Personal Data
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The Company may use Personal Data for the following purposes:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1). To provide and maintain our Service, including to monitor the usage of our Service.<br />
            2). To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You
            provide can give You access to different functionalities of the Service that are available to You as a
            registered user.<br />
            3). For the performance of a contract: the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any other contract with Us through the
            Service.<br />
            4). To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application's push notifications regarding updates or informative
            communications related to the functionalities, products or contracted services, including the security
            updates, when necessary or reasonable for their implementation.<br />
            5). To provide You with news, special offers and general information about other goods, services and events
            which we offer that are similar to those that you have already purchased or enquired about unless You have
            opted not to receive such information.<br />
            6). To manage Your requests: To attend and manage Your requests to Us.<br />
            7). For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as
            a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by
            Us about our Service users is among the assets transferred.<br />
            8) For other purposes: We may use Your information for other purposes, such as data analysis, identifying
            usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
            Service, products, services, marketing and your experience.<br />
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We may share Your personal information in the following situations:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            1) With Service Providers: We may share Your personal information with Service Providers to monitor and
            analyze the use of our Service, to contact You.<br />
            2) For business transfers: We may share or transfer Your personal information in connection with, or during
            negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our
            business to another company.<br />
            3) With Affiliates: We may share Your information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that are under common control with Us.<br />
            4) With business partners: We may share Your information with Our business partners to offer You certain
            products, services or promotions.<br />
            5) With other users: when You share personal information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be publicly distributed outside.<br />
            6) With Your consent: We may disclose Your personal information for any other purpose with Your consent.<br />
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Retention of Your Personal Data
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
            disputes, and enforce our legal agreements and policies
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
            shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Transfer of Your Personal Data
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Your information, including Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to maintained on computers located outside of Your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from Your jurisdiction.
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
            to that transfer.
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of Your data and other
            personal information.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> User Rights
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            1) Access: Users can request access to their data.<br />
            2) Correction: Users can request correction of inaccurate data.<br />
            3) Deletion: Users can request deletion of their data.<br />
            4) Objection: Users can object to data processing for certain purposes.<br />
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Prominent Disclosure
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Our app comprehensively collects, transmits, syncs, and stores user data, including phone numbers, email
            addresses, images, files, and device IDs, to enable various features and functionalities. This data collection
            occurs during normal app usage and is essential for providing our services.
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            1) Disclosure Placement: The disclosure regarding the collection of image information is prominently displayed
            within the app interface.<br />
            2) Separate from Privacy Policy: The disclosure is separate from the Privacy Policy or Terms of Service and is
            presented to users before they upload images.
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> User Consent and Data Security
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            User Consent:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            User consent is obtained before collecting or uploading any personal data, including images. Users are
            provided with clear information about data collection practices and have the option to provide or withhold
            consent.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Data Security:
          </p>
          <p style="text-align: justify; margin-left: 40px; margin-right: 20px;">
            We prioritize the security of user data and implement industry-standard measures to protect against
            unauthorized access, alteration, disclosure, or destruction of personal information.
          </p>

        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Security of Your Personal Data
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
            to protect Your Personal Data, We cannot guarantee its absolute security.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Security of Your Personal Data
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
            to protect Your Personal Data, We cannot guarantee its absolute security.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Security Measures
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We implement robust security measures to protect user data from unauthorized access, disclosure, alteration,
            or destruction.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Requests for User Consent
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            User consent is clear, unambiguous, and obtained through affirmative user action, such as tapping to accept or
            checking a box. We do not interpret navigation away from the disclosure as consent, and we do not use
            auto-dismissing or expiring messages to obtain consent. User consent must be granted before our app can begin
            to collect or access personal and sensitive user data.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Children's Privacy
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
            has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental consent, We take steps to remove that
            information from Our servers.
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.
          </p>
        </v-card-body>

        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> Contact Us
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal
            information, please contact us at support@payinpayout.co.in.
          </p>
        </v-card-body>


        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <b> . Changes to this Privacy Policy
            </b>
          </div>
        </v-card-title>
        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on
            this page, and we encourage you to review this policy periodically for updates.

          </p>
        </v-card-body>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="error" @click="
            PrivacyPolicy = false;
          ">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="NonRefundORNonCancellationPolicy" persistent width="1300">
      <v-card>
        <v-card-title>Non Refund OR Non Cancellation Policy</v-card-title>
        <v-divider></v-divider>

        <v-card-body>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;margin-top: 20px;">
            By accessing and using Payone Business, You accept and agree to the terms, conditions and rules without
            limitation and or qualification.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            We reserve the right to modify the terms at any time without giving You any prior notice. Your use of Payone
            Business following any such modification constitutes your agreement to follow and be bound by the terms as
            modified. Any additional terms and conditions, disclaimers, privacy policies and other policies applicable in
            general and/ or to specific areas of this website, Payone Business or to a particular service are also
            considered as terms.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Payone Business does not at any point of time during any transaction between the User and merchant/ vendor and
            or service provider take the ownership of any of the products/services provided by the merchant. Nor does
            Payone Business at any point asserts any rights or claims over the products/services offered by the merchant
            to the User. The cancellation/refund, if any, will be governed as per the terms and conditions of the
            aggregator or of the merchant/vendor. Payone Business has no role in governing refund/cancellation charges.
            Payone Business will not be responsible for refund/cancellation including any charges arising therefrom. Else
            were the Non-Refundable or Non-Cancelation Policy will not been there in Payone Business company.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Payone Business disclaims any accountability, legal, losses/damages or else, that might arise because of the
            act, omission or otherwise of any User on its website/mobile application or caused by the same. User/You
            expressly admit that Payone Business is only a payment facilitator & intermediary, and as such, stands
            indemnified from any accountability that might arise because of the same. You (User) moreover acknowledge that
            visiting/using Payone Business website/mobile application is an implicit reception/confirmation of this
            disclaimer on your part.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            You are requested to go through the merchant/vendor provided terms and conditions on their website and or
            platform. Payone Business is not responsible for your inability to access such terms and conditions or for any
            loss resulting from such terms and conditions or lack thereof. You agree and acknowledge that the actual
            contract for sale is directly between You and the merchant/vendor. Payone Business does not control or prevent
            changes in the published details and descriptions of websites/apps operated by the aggregator or the
            merchant/vendor and is not responsible for any content therein Payone Business has no control over the
            existence, quality, safety or legality of items displayed; the accuracy of the aggregator’s content or
            listings; the ability of the aggregator and merchant/vendor to sell items or provide services. Payone Business
            does not at any point of time during any transaction between You and aggregator/merchant/vendor, take the
            ownership of any of the listing, bookings or services offered by the aggregator/merchant/vendor. Nor does
            Payone Business at any point asserts any rights or claims over the same offered by the
            aggregator/merchant/vendor to You. The aggregator or the merchant/vendor is solely responsible for the
            content/listings/bookings made available by it through the Payone Business platform and You should contact the
            respective aggregator or merchant/vendor directly. For more information, we request You to contact the
            vendor/merchant/ service provider in order to get further clarifications and confirmation or the same can be
            confirmed on their websites as well.

          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Payone Business is not a warrantor of the content/listings/bookings/services being offered on Payone Business
            by various aggregators or merchant/vendors. You understand that any issue or disputes regarding the warranty,
            guarantee, quality, and service will be addressed as per the terms and conditions of the aggregator or the
            merchant/vendors, and You agree to handle such issues and disputes directly between You and the aggregator or
            merchant/vendor.Payone Business is not responsible for any warranty, guarantee, post-sale claims, genuineness
            of listings/bookings, content, products and services. Payone Business will not be liable for any claims
            including but not limited to any misrepresentation by the aggregator or the merchant/vendor in its
            content/listings/bookings.

          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Notwithstanding anything contrary contained herein, the service, the interface and api work, and their
            respective information, pricing and data, and availability are subject at any time and from time to time to
            human, mechanical , typographic, or other errors, oversights, mistakes, limitations, delays, service
            interruptions, including, without limitation, as may be due in whole or in part to, related to or arising out
            of (i) computer hardware and software, telecommunication and operating systems, databases, or business
            processes and procedures, other problems inherent in, or which may be associated with, the use of the internet
            and electronic communications including, without limitation, force majeure event , government / regulatory
            actions, orders, notifications etc. And / or and acts and omissions of third parties etc.

          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            Any amount transferred erroneously or for any reason by the User shall not be refunded to the User in any
            circumstances.
          </p>

          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The User understands and agrees that Payone Business is not a party to the contract between the User and the
            merchant establishment. Payone Business does not endorse any advertiser or merchant linked to its website.
            Furthermore, Payone Business is under no obligation to monitor the merchant establishment's service/products
            used by the User.
          </p>
          <p style="text-align: justify; margin-left: 20px; margin-right: 20px;">
            The merchant establishment alone will be responsible for all obligations under the contract including (without
            limitation) warranties or guarantees. Any dispute with or complaint against any merchant establishment must be
            directly resolved by the User with the merchant establishment. It is clarified that Payone Business shall not
            be responsible or liable for any deficiency in goods and/or services purchased using Payone Business wallet.
          </p>
        </v-card-body>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="error" @click="
            NonRefundORNonCancellationPolicy = false;
          ">OK</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</div></template>
<script>
export default {
  name: "Footer",
  data() {
    return { transfer: false, PrivacyPolicy: false, NonRefundORNonCancellationPolicy: false, }
  },
  computed: {
    details() {
      return this.$store.getters.details;
    },
  },
};
</script>

<style scoped>.footer_wrapper {
  width: 100%;
  height: 100%;
  background: #080f54;
  float: left;
}

.footer {
  width: 1000px;
  margin: 0 auto;
  height: 80px;
}

.headline {
  color: white;
  margin-top: 25px;
  float: right;
  font-weight: bold;
}

.headlineLink {
  color: white;
  margin-top: 5px;
  float: left;
  font-weight: bold;
}</style>
