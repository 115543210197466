<template>
  <div class="download">
    <div class="download_wrapper">
      <div class="display-3">Contact</div>
      <!-- <div class="contact_left">
        <v-text-field label="Your Name" single-line solo />
        <v-text-field label="Your Email" single-line solo />
        <v-text-field label="Mobile" single-line solo />
        <v-text-field label="Company Name" single-line solo />
        <v-text-field label="Company Website" single-line solo />
        <v-text-field label="Inquiry For" single-line solo />
      </div>
      <div class="contact_right">
        <v-select label="Country" single-line :items="country" solo />
        <v-select label="State" single-line :items="state" solo />
        <v-select label="City" single-line :items="city" solo />
        <v-textarea
          label="Your Message"
          no-resize
          single-line
          solo
          height="200"
          rows="9"
        />
        <v-btn class="submit">Submit</v-btn>
      </div>
      <div class="mapouter elevation-5">
        <div class="gmap_canvas">
          <iframe
            width="970"
            height="450"
            id="gmap_canvas"
            src=""
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div> -->

      <v-layout row wrap style="width: 100%;float: left">
        <v-flex xs4 class="col">
          <img src="../assets/question.svg" alt="" />
          <div class="subheading">Helpline</div>
          <div class="headline">8188800581</div>
        </v-flex>
        <v-flex xs4 class="col">
          <img src="../assets/customer-service.svg" alt="" />
          <div class="subheading">Business Enquiry</div>
          <div class="headline">8188800581</div>
        </v-flex>
        <v-flex xs4 class="col">
          <img src="../assets/server.svg" alt="" />
          <div class="subheading">Server Support</div>
          <div class="headline">9588888800</div>
        </v-flex>
      </v-layout>
    </div>
    <div
      class="download"
      style="background: white;padding-bottom: 40px;padding-top: 20px;margin-top: 40px"
    >
      <div class="download_wrapper">
        <div class="left">
          <img src="../assets/help.svg" alt="" />
        </div>
        <div class="center">
          <div class="headline">Address</div>
          <p class="subheading">
            Company Name & Registered/Operating Address:<br />
            M/s. Payone Business , Building No/Flat No.: 0 Road/Street: 0 Locality/Sub Locality:Sagari City/Town/Village : Jairajpur 
            <br />
            District: Azamgarh State: Uttar Pradesh PIN Code: 276121
            <br />
            <!-- Contact No : {{details.ContactMob}}
            <br /> -->
            Email ID : {{details.EmailId}}
          </p>
        </div>
        <div class="right">
          <img src="../assets/contact.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "../config/index.js";
export default {
  name: "Bank",
  data() {
    return {
      details: config.details,
      country: [
        "India",
        "United States",
        "United Kingdom",
        "United Arab Emirates",
        "South Africa",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegowina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D'Ivoire",
        "Croatia (local name: Hrvatska)",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "France, Metropolitan",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard and Mc Donald Islands",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (North)",
        "Korea (South)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia (Slovak Republic)",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "St. Pierre and Miquelon",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen Islands",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "U. S. Minor Outlying Islands",
        "Uganda",
        "Ukraine",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City State (Holy See)",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis And Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Yugoslavia",
        "Zaire",
        "Zambia",
        "Zimbabwe"
      ],
      state: [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Orissa",
        "Pondicherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Tripura",
        "Uttaranchal",
        "Uttar Pradesh",
        "West Bengal"
      ],
      city: [
        "Bangalore",
        "Calcutta",
        "Chennai",
        "Delhi",
        "Hyderabad",
        "Mumbai",
        "Pune",
        "Agra",
        "Agartala",
        "Ahmedabad",
        "Ahmednagar",
        "Aizwal",
        "Ajmer",
        "Aligarh",
        "Allahabad",
        "Allapuzha",
        "Alwar",
        "Alwaye",
        "Ambala",
        "Amritsar",
        "Anand",
        "Ankleshwar",
        "Anpara",
        "Asansol",
        "Aurai",
        "Aurangabad",
        "Auroville",
        "Ballabhgarh",
        "Barauni",
        "Bareilly",
        "Baroda",
        "Begusarai",
        "Behrampur",
        "Belgaum",
        "Bhadohi",
        "Bhagalpur",
        "Bharuch",
        "Bhatinda",
        "Bhavnagar",
        "Bhilai",
        "Bhilwara",
        "Bhimavaram",
        "Bhiwadi",
        "Bhopal",
        "Bhubaneshwar",
        "Bhuj",
        "Bijapur",
        "Bikaner",
        "Bilaspur",
        "Bokaro",
        "Bolpur",
        "Bongaigaon",
        "Burdwan",
        "Butibori",
        "Calicut",
        "Chandigarh",
        "Chandrapur",
        "Chas",
        "Chatrapur",
        "Chhapra",
        "Cochin",
        "Coimbatore",
        "Coonoor",
        "Cuddalore",
        "Cuttack",
        "Daman",
        "Darjeeling",
        "Dehradoon",
        "Davangere",
        "Dewas",
        "Dhaligaon",
        "Diu",
        "Dhanbad",
        "Dharwad",
        "Dibrugarh",
        "Duliajan",
        "Durgapur",
        "Erode",
        "Faridabad",
        "Faridkot",
        "Farukhabad",
        "Firozabad",
        "Gandhidham",
        "Gandhinagar",
        "Gangtok",
        "Ghaziabad",
        "Giridh",
        "Gorakhpur",
        "Guna",
        "Goa",
        "Guntur",
        "Gurgaon",
        "Guwahati",
        "Gwalior",
        "Haldia",
        "Haridwar",
        "Hoshiarpur",
        "Hosur",
        "Howrah",
        "Hubli",
        "Indore",
        "Jabalpur",
        "Jagdishpur",
        "Jaipur",
        "Jaisalmer",
        "Jaisinghpur",
        "Jalandhar",
        "Jalgaon",
        "Jalna",
        "Jalundhar",
        "Jammu",
        "Jamnagar",
        "Jamshedpur",
        "Jhansi",
        "Jodhpur",
        "Jorhat",
        "Kakinada",
        "Kalamaserry",
        "Kandla",
        "Kancheepuram",
        "Kanjkode",
        "Kannauj",
        "Kannur",
        "Kanpur",
        "Kanyakumari",
        "Karur",
        "Karwar",
        "Kazipet",
        "Kharagpur",
        "Kodaikanal",
        "Kolhapur",
        "Kollam",
        "Kota",
        "Kottayam",
        "Kozhikode",
        "Latur",
        "Lonavala",
        "Lucknow",
        "Ludhiana",
        "Madurai",
        "Malanpur",
        "Malda",
        "Mahabalipuram",
        "Mandideep",
        "Mangalore",
        "Manipal",
        "Mapuca",
        "Marcela",
        "Mardola",
        "Mathura",
        "Margao",
        "Meerut",
        "Midnapore",
        "Miraj",
        "Mirzapur",
        "Mohali",
        "Moradabad",
        "Munnar",
        "Mussoorie",
        "Muzaffarpur",
        "Mysore",
        "Nagpur",
        "Nainital",
        "Nanjangud",
        "Narsapur",
        "Nashik",
        "Navsari",
        "Nellore",
        "Nizamabad",
        "Noida",
        "Ooty",
        "Osmanabad",
        "Palai",
        "Palakole",
        "Palghat",
        "Panchkula",
        "Panipat",
        "Patiala",
        "Panvel",
        "Paradeep",
        "Patna",
        "Pirangute",
        "Pithampur",
        "Ponda",
        "Pondicherry",
        "Porbbhandur",
        "Port Blair",
        "Puri",
        "Raia",
        "Raichur",
        "Raipur",
        "Rajamundhry",
        "Rajkot",
        "Ranchi",
        "Ratlam",
        "Ratnagiri",
        "Rayagada",
        "Rohtak",
        "Roorkee",
        "Rourkela",
        "Salem",
        "Sambalpur",
        "Sangli",
        "Satna",
        "Secunderabad",
        "Shahjahanpur",
        "Shillong",
        "Shimonga",
        "Sholapur",
        "Sibsagar",
        "Silchar",
        "Siliguri",
        "Silvasa",
        "Singrauli",
        "Simla",
        "Solan",
        "Sonipat",
        "Srinagar",
        "Surat",
        "Tanjore",
        "Tezpur",
        "Thanjavur",
        "Tirunelveli",
        "Tirupati",
        "Tirupur",
        "Trichy",
        "Trivandrum",
        "Tutucorin",
        "Udaipur",
        "Udipi",
        "Valsad",
        "Vapi",
        "Varanasi",
        "Vasco",
        "Vijayawada",
        "Visakhapatnam",
        "Yamunanagar",
        "Warrangal",
        "Elsewhere in India",
        "Other than India"
      ]
    };
  }
};
</script>

<style scoped>
.download {
  width: 100%;
  background: #f0f0f0;
  float: left;
}
.contact_left {
  width: 500px;
  height: 530px;
  float: left;
  padding-right: 10px;
}
.display-3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #F5A21C;
  margin-bottom: 40px;
}

.contact_right {
  width: 500px;
  padding-left: 10px;
  height: 530px;
  float: left;
}
.download_wrapper {
  width: 1000px;
  min-height: 150px;
  margin: 0 auto;
}
.left {
  width: 130px;
}
.left img {
  width: 100px;
  margin-top: 30px;
  margin-bottom: 25px;
}
.center {
  width: 500px;
  height: 155px;
  float: left;
}
.right {
  width: 250px;
  float: left;
  height: 155px;
}
.right img {
  width: 250px;
  margin-top: 10px;
  float: left;
  height: 155px;
}
.headline {
  margin-top: 20px;
}
.subheading {
  margin-top: 10px;
}
.submit {
  margin: 0;
  color: White;
  width: 100%;
  margin-top: 3px;
}
.col img {
  margin-left: 90px;
  width: 150px;
  object-fit: contain;
  height: 200px;
  margin-top: 30px;
}
.col .headline {
  width: 100%;
  float: left;
  text-align: center;
  margin: 0px;
}
.col .subheading {
  float: left;
  width: 100%;
  text-align: center;
}
.display-3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #F5A21C;
}
.mapouter {
  width: 1000px;
  float: left;
  background: white;
  padding: 15px;
  border-radius: 10px;
}
</style>
