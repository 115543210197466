const host = "https://admin.payinpayout.co.in/";
export default {
  baseUrl: host + "ReCharge/androidapi.asmx/",
  moneyTransfer: host + "Recharge/MoneyTransfer.asmx/",
  aeps2Url: host + "Recharge/AEPSNY.asmx",
  microATMUrl: host + "Recharge/AEPSNI.asmx",
  apes2LoginUrl: "https://45.114.245.149/AEPS/login",
  dmr2: host + "ReCharge/opnDMRtransfer.asmx/",
  instapay: host + "ReCharge/INS2DMRTransfer.asmx/",
  dmrCyberplat: host + "ReCharge/INSPAYPDMTTransfer.asmx/",
  dmrPsy : host + "ReCharge/PysDMRApi.asmx/",
  dmrRNP : host + "ReCharge/RNPPayout.asmx/",
  BBPSRNP : host + "ReCharge/BBPSRP.asmx/",
  rbaseUrl: host + "ReCharge/APIs.aspx",
  sbaseUrl: host + "ReCharge/APIAndroid.aspx",
  planUrl: "http://submplan.myselfshop.in/MPLAN/Services/API.asmx/",
  path: "https://admin.payinpayout.co.in/ReCharge/UploadImages/",
  apikey: "f78b5ce5263b18b28f9ebfcb6e9976f3",
  dmrUrl: host + "ReCharge/CBRMoneytransfer.asmx/",
  logo: require("../assets/logo.png"),
  details: {
    AndroidAppName: "Payinpayout",
    PlayStoreUrl:
      "https://play.google.com/store/apps/details?id=com.mobile.bonrix.gayatrirecharge",
    EmailId: "support@payinpayout.co.in",
    ServerNo: "",
    HelpLineNo: "8188800581",
    Address1: "",
    Address2: "",
    ContactName: "payinpayout.co.in website Powered By M/s. Payone Business",
    ContactMob: "8188800581",
    Footer: "",
    InqueryNumber: "8188800581",
  },
  banks: [
    {
      Id: 1,
      BankName: "",
      CompanyName: "",
      AcountNo: "",
      Branch: "	",
      IfscCode: "",
      UserDomainName: null,
      AccountType: "CURRENT",
      CreatedBy: 0,
      IconImage: "",
    },    
  ],
};
