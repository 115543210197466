<template>
  <div>
    <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
      Daily Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="DailyReport.xls"
          :fields="fields"
          style="height: 30px; margin-top: 9px; margin-right: 10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>

        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float: right"
        >
          <input
            type="text"
            slot="activator"
            style="color: black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height: 34px; margin-left: 10px"
          @click="fetchstatement"
        >
          Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="statementlist"
      hide-actions
      :loading="loading"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          {{ new Date(parseInt( props.item.date)) | moment("DD-MM-YYYY") }}
        </td>
        <td>{{ props.item.openbalance }}</td>
        <td>{{ props.item.compayin }}</td>
        <td>{{ props.item.compayout }}</td>
        <td>{{ props.item.bookcomin }}</td>
        <td>{{ props.item.surchargein }}</td>
        <td>{{ props.item.surchargeOut }}</td>
        <td>{{ props.item.paymentin }}</td>
        <td>{{ props.item.paymentout }}</td>
        <td>{{ props.item.revertin }}</td>
        <td>{{ props.item.revertout }}</td>
        <td>{{ props.item.refundin }}</td>
        <td>{{ props.item.refundout }}</td>
        <td>{{ props.item.closebalance }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Opening Balance", value: "openbalance" },
        { text: "Commission Payin (+)", value: "compayin" },
        { text: "Commission Payout (-)", value: "compayout" },
        { text: "Booking ComIn (+)", value: "bookcomin" },
        { text: "Surcharge In (+)", value: "surchargein" },
        { text: "Surcharge Out (-)", value: "surchargeOut" },
        { text: "Payment In (+)", value: "paymentin" },
        { text: "Payment Out (-)", value: "paymentout" },
        { text: "Revert In (+)", value: "revertin" },
        { text: "Revert Out (-)", value: "revertout" },
        { text: "Refund In (+)", value: "refundin" },
        { text: "Refund Out (-)", value: "refundout" },
        { text: "Closing Balance", value: "closebalance" },
      ],
      fields: {
        "Created Date": "date",
        "Opening Balance": "openbalance",
        "Commission Payin (+)": "compayin",
        "Commission Payout (-)": "compayout",
        "Booking ComIn (+)": "bookcomin",
        "Surcharge In (+)": "surchargein",
        "Surcharge Out (-)": "surchargeOut",
        "Payment In (+)": "paymentin",
        "Payment Out (-)": "paymentout",
        "Revert In (+)": "revertin",
        "Revert Out (-)": "revertout",
        "Refund In (+)": "refundin",
        "Refund Out (-)": "refundout",
        "Closing Balance": "closebalance",
      },
      statementlist: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc =
        "?Mob=" +
        vm.user.phone +
        "&pin=" +
        vm.user.pin +
        "&StartDate=" +
        vm.date1.split("-").join("/") +
        "&EndDate=" +
        vm.date2.split("-").join("/") +
        "&Cmd=DailyReport&source=ANDROID";
      vm.$store
        .dispatch("saxios", {
          data: doc,
        })
        .then(function (res) {
          vm.pushstatement(res.data);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        console.log(data);
        let doc = {
          date: data.CreatedDate.replace("/Date(", "").replace(")/", ""),
          openbalance: data.openingbal,
          payin: data.paymentin,
          compayout: data.compayout,
          compayin: data.compayin,
          payout: data.paymentout,
          bookcomin: data.Bookingin,
          surchargein: data.surchargein,
          surchargeOut: data.surchargeout,
          paymentin: data.paymentin,
          paymentout: data.paymentout,
          revertin: data.revertin,
          revertout: data.revertout,
          refundin: data.refundin,
          refundout: data.refundout,
          closebalance: data.closingbal,
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    },
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  },
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
