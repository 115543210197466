<template>
  <v-carousel delimiter-icon="stop" style="margin-top: 80px">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      items: [
        {
          src: require("../assets/bank-banking-blue-50987.jpg")
        },
        {
          src: require("../assets/s1.png")
        },
        {
          src: require("../assets/s2.png")
        },
        {
          src: require("../assets/s3.png")
        },
        {
          src: require("../assets/s4.jpg")
        },
      ]
    };
  }
};
</script>

<style scoped></style>
