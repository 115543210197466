<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      Refund Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn @click="fetchstatement" icon>
          <v-icon style="margin-top:0px" color="white">refresh</v-icon>
        </v-btn> -->
        <download-excel
          :data="statementlist"
          name="Refund.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
                <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height:34px;margin-left:10px"
          @click="fetchstatement"
        >Search</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" :loading="loading" hide-actions>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          {{
          new Date(parseInt(props.item.date))
          | moment("DD-MM-YYYY, h:mm:ss a")
          }}
        </td>
        <td>{{ props.item.servicename }}</td>
        <td>{{ props.item.mobilenumber }}</td>
        <td>{{ props.item.openbalance }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.closebalance }}</td>
        <!-- <td>{{ props.item.status }}</td> -->
        <td>
        <v-btn
                  small
                  :class="checkrstatus(props.item.sStatus)"
                  style="width: 100%"
                  flat
                  >{{ checkrbtn(props.item.sStatus) }}</v-btn
                ></td>
        <td>{{ props.item.rechargeid }}</td>
        <td>{{ props.item.username }}</td>
        <td>{{ props.item.reason }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      fields: {
        "Created Date": "date",
        "Service Name": "servicename",
        "Mobile Number": "mobilenumber",
        "Opening Balance": "openbalance",
        Amount: "amount",
        "Closing Balance": "closebalance",
        Status: "status",
        "Recharge ID": "rechargeid",
        Username: "username",
        Reason: "reason",
        sStatus:"sStatus"
      },
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Txn Name", value: "servicename" },
        { text: "Account Number", value: "mobilenumber" },
        { text: "Opening Balance", value: "openbalance" },
        { text: "Amount", value: "amount" },
        { text: "Closing Balance", value: "closebalance" },
        { text: "Status", value: "status" },
        { text: "Transaction ID", value: "rechargeid" },
        { text: "Username", value: "username" },
        { text: "Reason", value: "reason" }
      ],
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    checkrstatus(status) {
      if (status == 2) return "success";
      if (status == 0) return "yellow";
      if (status == 3) return "warning";
      if (status == 1) return "error";
      return "primary";
    },
    checkrbtn(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return "Unknown";
    },
    checkstatus(status) {
      if (status == 2) return "success";
      if (status == 1) return "error";
      if (status == 0) return "warning";
      return "primary";
    },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { MobileNo: vm.user.phone, PinNo: vm.user.pin,
              StartDate: vm.date1.split("-").join("/"),
        EndDate: vm.date2.split("-").join("/")
};
      let url =
        vm.$root.loginType === "dmr" ? "RefundReportDateDMR" : "RefundReportDate";

      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          vm.loading = false;
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.CreatedDate.replace("/Date(", "").replace(")/", ""),
          servicename: data.ServiceName,
          mobilenumber: data.MobileNo,
          openbalance: data.OpeningBal,
          amount: data.Amount,
          closebalance: data.ClosingBal,
          status: data.Status,
          rechargeid: data.RechargeId,
          username: data.UserName,
          reason: data.Reason,
          sStatus: data.sStatus
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
