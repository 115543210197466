<template>
  <div class="download">
    <div class="download_wrapper">
      <div class="display-3">Downloads</div>
      <v-layout row wrap>
               <v-flex xs4 class="col">
                <a href="https://google.com" target="_blank">  <img src="../assets/googleplay.png" alt="" on-click=""/></a>
          <div class="headline">
            <v-btn  color="primary" @click="openLink()" class="submit"> Download Android App</v-btn>
         </div>
          <div class="subheading">
            We Provide Android application to make transaction very quickly and
            easily.
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink() {
      // URL of the Android app
      const url = 'http://allbonrixapplications.myappstore.co.in/BonrixAppStore/Raw_Details/Store/allbonrixapplications/APP/payin_payout.apk';
      // Open the URL in a new tab
      window.open(url, '_blank');
    }
  },
  name: "Download",
  computed: {
   
    details() {
      return this.$store.getters.details;
    },
  },
};
</script>

<style scoped>
.download {
  width: 100%;
  background: #f0f0f0;
  float: left;
  height: 500px;
}
.download_wrapper {
  width: 1000px;
  height: 500px;
  margin: 0 auto;
}
.col img {
  margin-left: 90px;
  width: 150px;
  object-fit: contain;
  height: 200px;
  margin-top: 30px;
}
.col .headline {
  width: 100%;
  text-align: center;
}
.col .subheading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.display-3 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #F5A21C;
}
</style>
