<template>
  <div>
    <div class="intro_wrapper">
      <div class="display-3">{{details.ContactName}} </div>
      <div class="headline">
        Payin Payout is a connected banking platform for business payments that simplifies the process of sending, receiving, and reconciling payments for businesses.
      </div>
         <!-- <div
      class="service_provider"
      :style="{ backgroundImage: 'url(' + back + ')' }"
    >
      <div class="display-3" style="color:white">Payin Payout has partnered with all leading banks</div>
      <div class="service_list">
        <div style="width: 100%;float:left">
          <img src="../assets/axis.png" alt="" style="padding-top: 10px;" />
          <img src="../assets/bob.png" alt="" style="padding-top: 10px;" />
          <img
            src="../assets/citi.png"
            alt=""
            style="padding-top: 10px;width: 130px;"
          />
          <img src="../assets/hdfc.png" style="margin-top: 0px" alt="" />
        </div>
        <div style="width: 100%;float:left">
          <img
            src="../assets/ICICI.png"
            style="margin-top: 0px;padding-top: 10px"
            alt=""
          />
          <img
            src="../assets/sbi.png"
            style="margin-top: 0px;padding-top: 10px"
            alt=""
          />
          <img
            src="../assets/rbl.png"
            style="margin-top: 0px;width: 130px;padding-top: 10px"
            alt=""
          />
          <img
            src="../assets/kotak.png"
            style="margin-top: 0px;padding-top: 10px"
            alt=""
          />
        </div>
      </div>
    </div>  -->
    </div>
  
  </div>
</template>

<script>
export default {
  name: "Introduction",
  data() {
    return {
      back: require("../assets/partner_bg.png")
    };
  },
  computed: {
    details() {
      return this.$store.getters.details;
    }
  }

};
</script>

<style scoped>
.intro_wrapper {
  width: 1000px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.display-3 {
  margin-top: 50px;
  color: #F5A21C;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.headline {
  margin-bottom: 50px;
  width: 100%;
  color: grey;
  text-align: center;
}
.service_provider {
  width: 100%;
  height: 450px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.service_list {
  width: 1000px;
  margin: 0 auto;
}
.service_provider img {
  width: 200px;
  margin-right: 50px;
  float: left;
  object-fit: contain;
}
</style>
