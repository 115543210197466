<template>
  <div>

    <CreditCardBillPayment v-show="way == 'CreditCardBillPayment'" />
    
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
  </div>
</template>
<script>
import CreditCardBillPayment from "./CreditCardBillPayment.vue";

import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
export default {
  data() {
    return {
      data: [],
      type: "BP",
      progress: false,
      preview: false,
      message: false,
      response: "",
      params: "",
      tabs: [
        // {
        //   name: "Electricity",
        //   img: require("../assets/imps.jpeg"),
        //   way: "electricity",
        //   width: 40
        // }
       
      ],
      way: "CreditCardBillPayment"
    };
  },
  methods: {
    fetchdata() {
      this.$parent.fetchdata;
    }
  },
  components: {
    CreditCardBillPayment,
    Progress,
    Preview,
    Message
  }
};
</script>
