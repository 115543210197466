import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";
import colors from 'vuetify/es5/util/colors'
let theme1 = {
  primary: "#1597d5", // #E53935
  secondary: "#ffd302", // #FFCDD2
  accent: "#263238" // #3F51B5
}
let theme2 = {
  secondary: "#1597d5",
  primary: "#ffd302", 
  accent: "#263238"
}
Vue.use(Vuetify, {
  iconfont: "md",
  theme: theme2
});
