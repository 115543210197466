<template>
  <div class="header">
    <div class="header_wrapper">
      <img src="../assets/payone.jpeg" alt="" />
      <button
        class="login"
        @click="login = true"
        v-if="user.phone === undefined"
      >
        Login
      </button>
      <button class="login" @click="openhome" v-else>Main</button>
      <div class="nav_wrapper">
        <a href="" v-scroll-to="'#contact'">Contact</a>
        <!-- <a href="" v-scroll-to="'#bank'">Bank Details</a>-->
        <!-- <a href="" v-scroll-to="'#download'">Downloads</a>  -->
        <a href="" v-scroll-to="'#service'">Services</a>
        <a href="" v-scroll-to="'#about'">About</a>
        <a href="" v-scroll-to="'#home'">Home</a>
      </div>
    </div>
    <Login v-if="login"></Login>
    <Progress v-if="loader"></Progress>
  </div>
</template>
<script>
import Login from "./Login.vue";
import Progress from "../components/Progress";
export default {
  name: "Header",
  data() {
    return {
      login: false,
      loader: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    openhome() {
      const vm = this;
      vm.$store.commit("setway", "recharge");
    }
  },
  components: {
    Login,
    Progress
  }
};
</script>

<style scoped>
.header {
  width: 100%;
  background: #080F54;
  height: 80px;
  position: fixed;
  top: 0px;
  z-index: 100;
}
.header_wrapper {
  width: 1000px;
  height: 80px;
  margin: 0 auto;
}
.header_wrapper img {
  width: 100px;
  height: 60px;
  object-fit: contain;
  margin-top: 10px;
}
.nav_wrapper {
  height: 40px;
  margin-top: 20px;
  float: right;
}
.nav_wrapper a {
  margin-right: 20px;
  padding-top: 8px;
  float: right;
  font-size: 1.2em;
  padding-bottom: 5px;
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
}
.nav_wrapper a:hover {
  border-bottom: 3px solid #F5A21C;
}
.login {
  margin-top: 20px;
  font-size: 1.2em;
  color: white;
  outline: none;
  font-weight: bold;
  background: #F5A21C;
  width: 120px;
  line-height: 1em;
  border-radius: 20px;
  height: 40px;
  float: right;
}
</style>
