<template>
  <div>
    <v-layout justify-space-around row>
      <v-card
        flat
        v-for="(card, i) in cards"
        :key="i"
        width="230px"
        tile
        :class="status === card.status ? 'border--green' : 'border--white'"
        @click="status = card.status"
      >
        <v-list class="pt-2 pb-2">
          <v-list-tile avatar>
            <v-list-tile-content>
              <v-list-tile-title dark
                >{{ card.name }} - {{ dashcard[card.count] }}</v-list-tile-title
              >
              <v-list-tile-sub-title class="headline"
                >₹ {{ dashcard[card.value] }}</v-list-tile-sub-title
              >
            </v-list-tile-content>
            <v-list-tile-avatar tile>
              <img :src="card.icon" />
            </v-list-tile-avatar>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-layout>
    <v-data-table
      v-if="headers.length"
      :headers="headers"
      :items="reports"
      hide-actions
      class="ma-2 mt-4"
    >
      <template
        slot="items"
        slot-scope="props"
        v-if="status === 4 || props.item.Status === status"
      >
        <td v-for="(item, key) in props.item" :key="props.index + key">
          <span v-if="key === 'Status'">
            <!-- <v-btn
              small
              :class="item === 2 ? 'success' : 'error'"
              style="width: 100%"
              flat
              >{{ item === 2 ? "Success" : "Fail" }}</v-btn
            > -->
            <!-- <v-btn
                  small
                  flat
                  :class="checkstatus(props.item.status)"
                  style="width: 100%"
                  >{{ props.item.status }}</v-btn
                > -->
                <v-btn
                  small
                  :class="checkrstatus(props.item.Status)"
                  style="width: 100%"
                  flat
                  >{{ checkrbtn(props.item.Status) }}</v-btn
                >
          </span>
          <span v-else>
            {{ formatText(item, key) }}
          </span>
        </td>

       
        
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      cards: [
        {
          name: "Success",
          value: "SuccessAmountTotal",
          count: "SuccessRechargeCount",
          color: "success",
          status: 2,
          icon: require("../assets/success.png"),
        },
        {
          name: "Pending",
          value: "PendingAmountTotal",
          count: "PendingRechargeCount",
          color: "success",
          status: 0,
          icon: require("../assets/pending.png"),
        },
        {
          name: "Failure",
          value: "FailAmountTotal",
          count: "FailRechargeCount",
          color: "success",
          status: 1,
          icon: require("../assets/failure.png"),
        },
        {
          name: "Total",
          value: "TotalAmount",
          count: "TotalRechargeCount",
          color: "success",
          status: 4,
          icon: require("../assets/total.png"),
        },
      ],
      status: 4,
    };
  },
  computed: {
    dashcard() {
      return this.$store.getters.dashcard;
    },
    user() {
      return this.$store.getters.user;
    },
    loginType() {
      return this.$store.getters.way;
    },
    headers() {
      let reports = this.$store.getters.dashReport;
      let headers = [];
      let keys = [
        "Status",
        "CreatedDate",
        "ServiceName",
        "MobileNo",
        "OpeningBal",
        "Amount",
        "Id",
      ];
      keys.forEach((key) => {
        headers.push({ text: key, value: key });
      });
      return headers;
    },
    reports() {
      let reports = this.$store.getters.dashReport;
      let list = [];
      if (reports.Data && reports.Data.length) {
        reports.Data.forEach((item) => {
          let doc = {};
          this.headers.forEach(({ value }) => {
            doc[value] = item[value];
          });
          list.push(doc);
        });
      }
      return list;
    },
  },
  methods: {
    checkstatus(status) {
      if (this.change) {
        status = status.toLowerCase();
        if (status == "success") return "success";
        if (status == "pending") return "yellow";
        if (status == "in process") return "warning";
        if (status == "fail") return "error";
      }
      return "primary";
    },
    checkrstatus(status) {
      if (status == 2) return "success";
      if (status == 0) return "yellow";
      if (status == 3) return "warning";
      if (status == 1) return "error";
      return "primary";
    },
    checkrbtn(status) {
      if (status == 2) return "Success";
      if (status == 0) return "Pending";
      if (status == 3) return "In Process";
      if (status == 1) return "Failure";
      return "Unknown";
    },
    getbackground(status) {
      if (this.change) {
        status = status.toLowerCase();
        if (status == "success") return "#ffff";
        if (status == "pending") return "#FFF59D";
        if (status == "in process") return "#FFF59D";
        if (status == "fail") return "#EF9A9A";
      }
      return "#90CAF9";
    },
    getrbackground(status) {
      if (status == 2) return "#ffff";
      if (status == 0) return "#B0BEC5";
      if (status == 3) return "#FFF59D";
      if (status == 1) return "#EF9A9A";
      return "#90CAF9";
    },
    fetch() {
      let vm = this;
      if (!vm.user.phone) return;
      let data = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
      };
      if (vm.loginType === "dmr") data.Balance = "DMR";
      let url =
        vm.loginType === "dmr"
          ? "TodayRechrageSummaryDMR"
          : "TodayRechrageSummary";
      vm.$store.dispatch("axiosget", {
        url: url,
        way: "setdashcard",
        data: data,
      });
    },
    fetchReport() {
      const vm = this;
      let url =
        vm.loginType === "dmr"
          ? "RechargeSearchFilterDMR"
          : "RechargeSearchFilter";
      let startDate = moment().format("YYYY-MM-DD");
      let data = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: startDate,
        EndDate: startDate,
        Balance: "DMR",
        status: 4,
      };
      vm.$store.dispatch("axiosget", {
        url: url,
        way: "setDashReport",
        data: data,
      });
    },
    formatText(text, key) {
      if (key === "CreatedDate") {
        text = text.split("/Date(").join("").split(")/").join("");
        text = moment(new Date(Number(text))).format("DD-MM-YYYY hh:mm a");
      }
      return text;
    },
  },
  mounted() {
    let vm = this;
    vm.fetch();
    vm.fetchReport();
  },
};
</script>

<style scoped>
.border--white {
  cursor: pointer;
  border-bottom: 3px solid white;
}
.border--green {
  cursor: pointer;
  border-bottom: 3px solid #4caf50;
}
</style>
