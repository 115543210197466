<template>
  <div class="about_wrapper">
    <div class="about">
      <div class="about_left">
        <div class="display-2">
          About us
        </div>
        <img src="../assets/about.jpg" />
        <div class="title">
          Payin Payout has partnered with leading banks like ICICI Bank, State Bank of India, Axis Bank, Yes Bank, and other major Indian banks for connected banking, allowing businesses to directly make payments from its dashboard. Also it offers additional business tools for managing end-to-end payroll, expense management, and much more from the platform.
        </div>
      </div>
      <div class="about_right">
        <div class="title" style="margin-top: 70px;">
          Business Payments Made Simple For Small Businesses
Payin Payout allows you to easily connect your bank accounts and accounting software to streamline vendor payments, collect customer payments, and reconcile transactions. Say goodbye to the hassle of manual payment processing and hello to a more efficient and organized payment system for your business.
        </div>
        <div class="title" style="margin-top: 20px">
          Say goodbye to the hassle of managing multiple passwords and logging into different internet banking portals to view balances, pay vendors, and track customer payments. With our platform, you can effortlessly manage all your banking and payments from a single, easy-to-use dashboard.

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped>
.about_wrapper {
  width: 100%;
  height: 450px;
  background: #f0f0f0;
}
.about {
  width: 1000px;
  height: 450px;
  margin: 0 auto;
}
.about_left {
  width: 500px;
  height: 400px;
  float: left;
}
.about_right {
  width: 500px;
  height: 400px;
  float: left;
}
.display-2 {
  margin-top: 10px;
  color: #463734;
}
.about_left img {
  width: 500px;
  margin-top: 20px;
  border-radius: 20px;
}
.about_left .title {
  margin-top: 20px;
  font-weight: normal;
  color: grey;
}

.about_right .title {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: normal;
  color: grey;
}
</style>
