<template>
  <div>
    <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      Prepaid
    </div>
    <v-text-field
      type="text"
      v-model="rechargernumber"
      style="margin: 0 auto; height: 60px"
      label="Enter Mobile Number"
    ></v-text-field>
    <v-autocomplete
      v-model="operator"
      :items="sortedproviver"
      label="Select Operator"
      style="margin: 0 auto; height: 60px"
      chips
      item-text="name"
      item-value="name"
    >
      <template slot="selection" slot-scope="data">
        <v-chip :selected="data.selected" close class="chip--select-multi">
          <v-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <template>
          <v-list-tile-avatar>
            <img :src="data.item.avatar" @error="errorimg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile v-html="data.item.name"></v-list-tile>
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>

    <v-autocomplete
      style="margin: 0 auto; height: 53px"
      v-model="circle"
      :items="circles"
      label="Select Circle"
    />
    <v-btn
      small
      class="primary"
      depressed
      round
      style="
        float: left;
        font-size: 0.8em;
        padding-left: 15px;
        padding-right: 15px;
      "
      @click="fetchoffer"
      >Check Offers</v-btn>
    <v-btn
      small
      class="primary"
      depressed
      round
      style="
        float: right;
        font-size: 0.8em;
        padding-left: 15px;
        padding-right: 15px;
      "
      @click="fetchplan"
      >Browse Plan</v-btn
    >
    <br />
    <br />
    <v-text-field
      type="text"
      v-model="rechargeramount"
      style="margin: 0 auto; height: 60px"
      label="Enter Recharge Amount"
    />

    <div style="width: 100%; float: left">
      <v-btn
        class="light-blue lighten-1"
        dark
        style="width: 100%; margin: 0 auto"
        @click="submit"
        >RECHARGE</v-btn
      >
    </div>
    <Plans :model="showplan" v-if="showplan" :pplans="plans" />
    <Offers :model="showoffer" v-if="showoffer" :pplans="offers" />
  </div>
</template>

<script>
import Plans from "../plan/Plans.vue";
import Offers from "../plan/Offers.vue";

export default {
  data() {
    return {
      operator: "",
      type: "",
      provider: [],
      circles: [
        "Andhra Pradesh Telangana",
        "Assam",
        "Bihar Jharkhand",
        "Chennai",
        "Delhi NCR",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu Kashmir",
        "Karnataka",
        "Kerala",
        "Kolkata",
        "Madhya Pradesh Chhattisgarh",
        "Maharashtra Goa",
        "Mumbai",
        "North East",
        "Orissa",
        "Punjab",
        "Rajasthan",
        "Tamil Nadu",
        "UP East",
        "UP West",
        "West Bengal",
      ],
      freechargeprovider: [
        "Aircel",
        "Airtel",
        "BSNL",
        "Docomo",
        "Tata Docomo",
        "Idea",
        "MTNL",
        "MTS",
        "Reliance",
        "Uninor",
        "Vodafone",
        "Virgin",
        "VirginCDMA",
        "T24",
        "MTNL",
        "RelianceJio",
        "Airtel",
        "BSNL",
        "Docomo",
        "Idea",
        "Docomo",
        "Vodafone",
        "Reliance",
        "Aircel",
        "MTS",
        "AirtelDTH",
        "DishTV",
        "RelianceBigTv",
        "SunDirect",
        "TataSky",
        "VideoconD2H",
      ],
      freechargeidArray: [
        "1",
        "2",
        "3",
        "5",
        "42",
        "6",
        "9",
        "22",
        "11",
        "13",
        "14",
        "30",
        "31",
        "43",
        "54",
        "90",
        "56",
        "58",
        "59",
        "60",
        "61",
        "62",
        "64",
        "65",
        "66",
        "17",
        "4",
        "16",
        "18",
        "19",
        "20",
      ],
      circleid: [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "33",
        "34",
        "35",
      ],
      circle: "",
      rechargetype: ["Recharge", "STV"],
      rechargernumber: "",
      rechargeramount: "",
      showplan: false,
      showoffer: false,
      plans: [],
      offers: [],
      rtype: "RR",
    };
  },
  components: {
    Plans,
    Offers,
  },
  computed: {
    getprovider() {
      return this.$store.getters.operator;
    },
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
    sortedproviver() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      let provider = this.provider;
      return provider.sort(compare);
    },
  },
  watch: {
    getprovider() {
      let vm = this;
      vm.pushoperator();
    },
    clear() {
      this.cleardata();
    },
    rechargernumber(value) {
      if (value.length == 10) {
        let enableoperator = localStorage.enableoperator;
        if (enableoperator == "true" || enableoperator == undefined) {
          let oftype = localStorage.oftype;
          if (oftype == null || oftype == "mplan") {
            this.checkprovider();
          } else {
            this.checkpaytm();
          }
        }
      }
    },
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.rechargernumber = "";
      vm.rechargeramount = "";
      vm.cricle = "";
      vm.rtype = "RR";
    },
    errorimg(e) {
      e.target.src = require("../assets/logo_single.png");
    },
    pushoperator() {
      let vm = this;
      vm.provider = [];
      for (let i = 0; i < vm.getprovider.length; i++) {
        let doc = vm.getprovider[i];
        let path = this.$store.getters.path;
        if (doc.RechargeTypeName == "Recharge") {
          let data = {
            avatar: path + doc.CircleImg,
            name: doc.ServiceName,
          };
          vm.provider.push(data);
        }
      }
    },
    fetchplan() {
      let vm = this;
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.circle == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Circle Required",
          type: "error",
        });
      let url = "SIMPLE";
      let doc =
        "cricle=" +
        vm.checkcircle(vm.circle) +
        "|operator=" +
        vm.operator +
        "&_=" +
        new Date().getTime();
      vm.$parent.progress = true;
      vm.$store
        .dispatch("planapi", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.showplan = false;
          vm.plans = res.data.records;
          vm.showplan = true;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error.message,
            type: "error",
          });
        });
    },
    checkcircle(circ) {
      if (circ == "Andhra Pradesh") {
        circ = "Andhra Pradesh Telangana";
      } else if (circ == "Bihar" || circ == "Jharkhand") {
        circ = "Bihar Jharkhand";
      } else if (circ == "Delhi") {
        circ = "Delhi NCR";
      } else if (circ == "Jammu and Kashmir") {
        circ = "Jammu Kashmir";
      } else if (circ == "Madhya Pradesh" || circ == "Chhattisgarh") {
        circ = "Madhya Pradesh Chhattisgarh";
      } else if (circ == "Maharashtra" || circ == "Goa") {
        circ = "Maharashtra Goa";
      }
      return circ;
    },

    fetchoffer() {
      let vm = this;
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.circle == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Circle Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Mobile Number Required",
          type: "error",
        });
      if (vm.rechargernumber.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Valid Phone Number Required",
          type: "error",
        });
      let url = "ROFFER";
      let doc =
        "offer=roffer|tel=" +
        vm.rechargernumber +
        "|operator=" +
        vm.operator +
        "&_=" +
        new Date().getTime();
      vm.$parent.progress = true;
      vm.$store
        .dispatch("planapi", {
          url: url,
          data: doc,
        })
        .then(function (res) {
          vm.$parent.progress = false;
          vm.offers = res.data.records;
          vm.showoffer = true;
        })
        .catch(function (error) {
          vm.$parent.progress = false;
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error.message,
            type: "error",
          });
        });
    },

    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (vm.operator == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Operator Required",
          type: "error",
        });
      if (vm.rechargernumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Number Required",
          type: "error",
        });
      if (vm.rechargernumber.length != 10)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "10 Digit Number Required",
          type: "error",
        });
      if (vm.rechargeramount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Recharge Amount Required",
          type: "error",
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=" +
        vm.rtype +
        "+" +
        vm.operator +
        "+" +
        vm.rechargernumber +
        "+" +
        vm.rechargeramount +
        "+" +
        vm.user.pin +
        "&source=ANDROID";
      let data = {
        Operator: vm.operator,
        "Recharge Number": vm.rechargernumber,
        "Recharge Amount": vm.rechargeramount,
      };
      vm.$parent.sync = {
        title: vm.operator,
        subtitle: vm.rechargernumber,
        status: "process",
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    },
    checkprovider() {
      let vm = this;
      vm.$parent.progress = true;
      let url =
        "https://cors-anywhere.herokuapp.com/https://www.freecharge.in/rest/operators/mapping/v3/" +
        vm.rechargernumber;
      vm.axios
        .get(url)
        .then(function (res) {
          vm.$parent.progress = false;
          let oid = res.data.prepaid.operatorId;
          let pcheck = null;
          vm.freechargeidArray.forEach(function (value, id) {
            if (oid == value) {
              pcheck = vm.freechargeprovider[id];
            }
          });
          if (pcheck != null) {
            vm.provider.forEach(function (value) {
              if (value.name == pcheck) {
                vm.operator = value.name;
              }
            });
          }
          vm.circleid.forEach(function (value, id) {
            if (res.data.prepaid.circleId == value) {
              vm.circle = vm.circles[id];
            }
          });
        })
        .catch(function () {
          vm.$parent.progress = false;
        });
    },
    checkpaytm() {
      let vm = this;
      vm.$parent.progress = true;
      let url =
        "https://cors-anywhere.herokuapp.com/https://digitalapiproxy.paytm.com/v1/mobile/getopcirclebyrange?channel=web&version=2&number=" +
        vm.rechargernumber +
        "&child_site_id=1&site_id=1&locale=en-in";
      vm.axios
        .get(url)
        .then(function (res) {
          vm.$parent.progress = false;
          let doc = res.data;
          let circle = doc.Circle;
          let operator = doc.Operator;
          vm.circle = circle;
          vm.provider.forEach(function (value) {
            if (value.name == operator) {
              vm.operator = value.name;
            }
          });
        })
        .then(function () {
          vm.$parent.progress = false;
        });
    },
  },
  mounted() {
    let vm = this;
    if (vm.getprovider.length != 0) {
      vm.pushoperator();
    }
  },
};
</script>
