<template>
    <div>
        <v-card>
            <v-toolbar class="secondary" dense style="color: white; font-weight: bold">
                <input type="file" @change="onFileChange" ref="fileInput" dense class="pt-0 mr-3" color="white"
                    accept=".xlsx, .xls" />
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn class="success" depressed round style="
        float: right;
        font-size: 0.9em;
        padding-left: 15px;
        padding-right: 15px;        
      " @click="handleFileUpload(file)">Import File</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" href="https://admin.payinpayout.co.in/ReCharge/Download/SamplePayout.xlsx"
                        depressed round style="
        float: right;
        font-size: 0.9em;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 10px;
      " color="primary">Download Sample file</v-btn>
                </v-toolbar-items>
            </v-toolbar></v-card>
        <v-card>
            <v-data-table :headers="headers" :items="table" hide-actions :search="search" :loading="loading"
                disable-initial-sort>
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                    <td>
                        {{ props.item.BankName }}
                    </td>
                    <td>
                        {{ props.item.AccountName }}
                    </td>
                    <td>
                        {{ props.item.AccountNumber }}
                    </td>
                    <td>
                        {{ props.item.IFSCCode }}
                    </td>
                    <td>
                        {{ props.item.Amount }}
                    </td>
                    <td>
                        {{ props.item.timestamp }}
                    </td>

                    <td>
                        <v-btn small :class="checkrstatus(props.item.Status)" style="width: 100%" flat>{{
                            checkrbtn(props.item.Status) }}</v-btn>
                    </td>
                  
                </template>

                <template slot="footer">
                    <tr>
                        <td colspan="4" style="text-align: right; font-weight: bold;">Grand Total:</td>
                        <td>{{ grandTotal }}</td>
                    </tr>
                </template>
            </v-data-table>

        </v-card>
        <br />
        <v-spacer></v-spacer>
        <div style="width: 100%; ">
            <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="MoneyTransfer"
                :disabled="isButtonDisabled">Submit</v-btn> <br />
        </div>

        <v-dialog v-model="transfer" persistent width="400">
            <v-card>
                <v-card-title>Transfer Money</v-card-title>
                <v-divider></v-divider>
                <v-card-title>1. Please don't Refresh or go Back from the screen while processing.</v-card-title>
                <v-card-title>2. For transaction status, refer Last Transactions menu.</v-card-title>
                <v-divider></v-divider>
                <v-card-title style="padding-bottom: 0px">
                    <div style="margin-bottom: 10px">
                        <div>Total Transfer Amount is: {{ this.grandTotal }}</div>

                        <div><v-text-field v-model="password"  type="password" style="margin: 0 auto; height: 60px" label="Enter TPIN"
                                solo /></div>
                    </div>
                </v-card-title>

                <v-card-actions>
                    <v-btn flat color="success" @click="submit" :disabled="iscnfButtonDisabled">Confirm and pay</v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn flat color="error" @click="
                        transfer = false;
                    isButtonDisabled = false;
                    ">Cancel</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Progress v-if="progress" />

    </div>
</template>
<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";

import Complaint from "../components/Complaint.vue";
import Print from "../dmr3/Print.vue";
import * as XLSX from 'xlsx';
import moment from "moment";

export function generateGUID() {
    const timestamp = Date.now();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    return `${guid}-${timestamp}`;
}
export default {
    props: ["change"],
    components: {
        Progress,
        Preview,
        Message,
        Complaint,
        Print,
    },
    data() {
        return {
            password: "",
            viewstatus: false,
            way: "prepaid",
            loading: false,
            data: [],
            progress: false,
            preview: false,
            message: false,
            response: "",
            params: "",
            search: "",
            type: "BP",
            phone: "",
            tid: "",
            vchange: this.change,
            pagination: {},
            selectedFile: null,
            complaint: false,
            headers: [
                {
                    text: "BankName",
                    value: "BankName",
                    align: "left",
                },
                { text: "AccountName", value: "AccountName" },
                { text: "AccountNumber", value: "AccountNumber" },
                { text: "IFSCCode", value: "IFSCCode" },
                { text: "Amount", value: "Amount" },
                { text: "TimeStamp", value: "TimeStamp" },
                { text: "Status", value: "Status" },
               
            ],
            report: [],
            table: [],
            summary: {},
            isButtonDisabled: true,
            transfer: false,
            iscnfButtonDisabled: false,
        };
    },
    methods: {
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        clearFileInput() {
            this.selectedFile = null;
            this.$refs.fileInput.value = '';
        },
        handleFileUpload() {
            debugger;
            if (this.selectedFile) {
                const file = this.selectedFile;
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    // Assuming the first sheet
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    this.processExcelData(jsonData);

                };
                reader.readAsArrayBuffer(file);
            }
        },
        processExcelData(data) {
            if (data.length === 0) {
                console.error('No data found in the Excel file');
                return;
            }
            if (data.length <= 101) {
                // Extract headers
                this.headers = data[0].map((header) => ({
                    text: header,
                    value: header,
                }));

                console.log('Headers:', this.headers);

                // Extract rows
                this.table = data.slice(1).map((row) => {
                    const rowData = {};
                    row.forEach((cell, index) => {
                        rowData[this.headers[index].value] = cell;
                        rowData.timestamp = generateGUID();
                        rowData.Status = 0;
                    });
                    return rowData;
                });
                this.isButtonDisabled = false;
                this.selectedFile = null;
                console.log('Table Data:', this.table);
            }
            else {
                alert("Only accept maximum 100 records");
            }
            this.clearFileInput();
        },

        MoneyTransfer() {

            let vm = this;
            if (vm.user.length == 0)
                return vm.$store.dispatch("notify", {
                    vm: vm,
                    message: "Authentication Required!",
                    type: "warn",
                });
            this.isButtonDisabled = true;
            this.transfer = true;
        },

        sendMoney(doc) {
            debugger;
            const vm = this;
            return new Promise((resolve, reject) => {
                vm.$store
                    .dispatch("axiosdmrpost", {
                        url: "SendMoneyUsingBankAccount",
                        data: doc,
                        type: "dmrRNP",
                    })
                    .then(function ({ data }) {
                        if (data.status == "True") {
                            resolve(data.message);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch(function (error) {
                        reject(error.message);
                    });
            });
        },

        async submit() {
            debugger;
            let vm = this;

            if (vm.password === '')
                return vm.$store.dispatch("notify", {
                    vm: vm,
                    message: "TPIN is Required!",
                    type: "error",
                });
            if (vm.balance < vm.grandTotal) {
                return vm.$store.dispatch("notify", {
                    vm: vm,
                    message: "Insufficient Balance!",
                    type: "error",
                });
            }
            this.iscnfButtonDisabled = true;
            for (let index = 0; index < vm.table.length; index++) {
                if (vm.table[index].Status == 0) {
                    const item = vm.table[index];

                    let transfermessage = "";

                    let doc = {
                        MobileNo: vm.user.phone,
                        PinNo: vm.user.pin,
                        Mobile: vm.user.phone,
                        Name: item.AccountName.trim(),
                        AccountNumber: item.AccountNumber.trim(),
                        IFSC: item.IFSCCode,
                        BankName: item.BankName.trim(),
                        Mode: "IMPS",
                        Amount: item.Amount,
                        Password: vm.password,
                        TimeStamp:item.timestamp
                    };
                    vm.loader = true;

                    let res = await vm.sendMoney(doc);
                    vm.table[index].Status = 2;
                    vm.table[index].Message = res;
                    //    vm.$store
                    //         .dispatch("axiosdmrpost", {
                    //             url: "SendMoneyUsingBankAccount",
                    //             type: "dmrRNP",
                    //             data: doc,
                    //         })
                    //         .then(function (res) {
                    //             vm.loader = false;
                    //             let data = res.data;
                    //             if (data.status === "True") {
                    //                 debugger;
                    //                 transfermessage = data.message;
                    //                 vm.table[index].Status = 2;
                    //                 vm.table[index].Message = transfermessage;
                    //             }
                    //             else {
                    //                 vm.table[index].Status = 2;
                    //                 vm.table[index].Message = transfermessage;
                    //             }
                    //         })
                    //         .catch(function (err) {
                    //             transfermessage = err.message;
                    //             vm.table[index].Status = 2;
                    //             vm.table[index].Message = transfermessage;
                    //             //   vm.notification("error", err.message);
                    //         }).finally(() => {
                    //         });

                    // Toggle status between 0 (Pending) and 2 (Success) or set it to 0 if you want to reset all statuses to 0
                    //  this.$set(this.table, index, { ...item, Message: transfermessage, Status: item.Status === 0 ? 2 : 0 });
                    // Wait for the next tick to update the UI
                    // alert(transfermessage);
                    //await this.$nextTick();
                }

                //  this.isButtonDisabled = false;
             
            }
            this.iscnfButtonDisabled = false;
                // Re-enable the button after the operation completes or fails
                this.transfer = false;
        },
        checkrstatus(status) {
            if (status == 2) return "Processed";
            if (status == 0) return "grey";
            if (status == 3) return "warning";
            if (status == 1) return "error";
            return "primary";
        },
        checkrbtn(status) {
            if (status == 2) return "Processed";
            if (status == 0) return "Pending";
            if (status == 3) return "In Process";
            if (status == 1) return "Failure";
            return "Unknown";
        },
        checkstatus(status) {
            status = status.toLowerCase();
            if (status == "success") return "success";
            if (status == "pending") return "grey";
            if (status == "in process") return "warning";
            if (status == "fail") return "error";
            return "primary";
        },
        getbackground(status) {
            status = status.toLowerCase();
            if (status == "success") return "#ffff";
            if (status == "pending") return "#B0BEC5";
            if (status == "in process") return "#FFF59D";
            if (status == "fail") return "#EF9A9A";
            if (status == "processed") return "#FFF59D";
            return "#90CAF9";
        },
        changeview() {
            this.$parent.change = true;
        },
        openremark(item) {
            let vm = this;
            vm.tid = item.tid;
            if (item.tid == null) return;
            vm.complaint = true;
        },
        handleresize() {
            let vm = this;
            let width = window.innerWidth - 330 - 293 + "px";
            vm.$store.commit("setrwidth", width);
        },
        showtable() {
            let vm = this;
            vm.table = [];
            let pos = 0;
            let mobile = "";
            let account = "";
            let list = [];
            for (let i = 0; i < vm.report.length; i++) {
                let doc = vm.report[i];
                if (mobile != doc.MobileNo || account != doc.accountno) {
                    pos++;
                }

                let data = {
                    orderno: doc.RechargeId,
                    service: doc.ServiceName,
                    mobile: doc.MobileNo,
                    pos,
                    status: doc.Status,
                    type: doc.RechargeType,
                    com: doc.Commission,
                    tid: doc.OperatorId,
                    amount: doc.Amount,
                    openbalance: doc.OpeningBal,
                    closebalance: doc.ClosingBal,
                    time: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
                    surcharge: doc.PersonalSurcharge,
                    recipient_name: doc.recipient_name,
                    accountno: doc.accountno,
                    bankName: doc.bankName,
                    BankRefrenceNo: doc.BankRefrenceNo,
                    Reason: doc.Reason,
                    RechargeId: doc.RechargeId,
                };
                list.push(data);
            }
            vm.table = list;
            vm.loading = false;
        },
        fetchstatement() {
            let vm = this;
            if (vm.user.length != 0) {
                vm.loading = true;
                let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone, PageSize: 50 };
                let url =
                    vm.$root.loginType === "dmr"
                        ? "LastTransactionDMR"
                        : "LastTransaction";

                vm.$store
                    .dispatch("axios", {
                        url: url,
                        data: doc,
                    })
                    .then(function (res) {
                        vm.report = [];
                        if (res.data.Data != null) {
                            let list = res.data.Data.sort(
                                (a, b) => b.RechargeId - a.RechargeId
                            );
                            list.map((i) => console.log(i.RechargeId));
                            vm.report = list;
                            vm.showtable();
                        }
                        vm.loading = false;
                    })
                    .catch(function (error) {
                        vm.$store.dispatch("notify", {
                            vm: vm,
                            message: error,
                            type: "warn",
                        });
                    });
            }
        },

        filterByPos(pos) {
            const vm = this;
            let list = [];
            vm.table.forEach((item) => {
                if (item.pos === pos && vm.isPrintable(item.status)) {
                    list.push(item);
                }
            });
            return list;
        },
        getsummary(doc) {
            let vm = this;
            vm.viewstatus = true;
            vm.summary = doc;
            // vm.summary = {
            //   "Order No": doc.orderid,
            //   "Name ": doc.name,
            //   Mobile: doc.mobile,
            //   Amount: doc.amount,
            //   Status: doc.status,
            //   Time: doc.time,
            //   "Bank Transaction Id": doc.bank_txtid,
            //   "Transaction Id": doc.tid
            // };
        },
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        logo() {
            return this.$store.getters.logo;
        },
        getprofile() {
            return this.$store.getters.profile.Data;
        },
        grandTotal() {
            return this.table.reduce((total, item) => total + item.Amount, 0);
        },
        balance() {
            return this.$store.getters.balance;
        },
    },
    watch: {},
    mounted() {
        let vm = this;
        //   vm.fetchstatement();
    },
};
</script>
  
<style scoped>
.search {
    width: 200px;
    float: right;
    height: 34px;
    background: white;
    outline: none;
    margin-left: 20px;
    padding-left: 10px;
    font-size: 1em;
}

.searchbtn {
    margin-right: 15px;
    height: 34px;
    margin-left: 20px;
    float: right;
    margin-top: 0px;
}
</style>
  