<template>
    <div style="width: 350px; margin-left: auto; margin-right: auto">
      <div
        style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
        class="headline"
      >
      </div>
     
      <div style="width: 100%; float: left">
        <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="submit"
          >Get New T PIN</v-btn
        >
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        MobileNo: "",
      
      };
    },
    computed: {
      user() {
        return this.$store.getters.user;
      },
      clear() {
        return this.$store.getters.clear;
      },
    },
    watch: {
      clear() {
        this.cleardata();
      },
    },
    methods: {
      cleardata() {
        let vm = this;
        vm.number = "";
        vm.username = "";
      },
      submit() {
        let vm = this;
        if (vm.user.length == 0)
          return vm.$store.dispatch("notify", {
            vm: vm,
            message: "Authentication Required!",
            type: "warn",
          });
     
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
      let url = "RStpassword"
      vm.$store
        .dispatch("axios", {
          url: url,
          data: doc
        })
        .then(function(res) {
          if (res.data.Status) {
            return vm.$store.dispatch("notify", {
            vm: vm,
            message: res.data.Message,
            type: "Success",
          });
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
      },
    },
  };
  </script>
  
  <style>
  .leftdiv {
    width: 300px;
    float: left;
    margin-bottom: 10px;
  }
  .rightdiv {
    margin-top: 10px;
    float: left;
    height: 100px;
  }
  </style>
  