<template>
  <div style="width:350px;margin-left:auto;margin-right:auto">
    <div
      style="width:100%;text-align:center;margin-top:15px;height:50px"
      class="headline"
    >
      Complaint
    </div>
    <v-text-field
      type="text"
      v-model="transactionid"
      style="margin:0 auto;height:60px"
      label="Transaction ID"
      solo
    ></v-text-field>
    <v-text-field
      type="text"
      v-model="remark"
      style="margin:0 auto;height:60px"
      label="Remark"
      solo
    />
    <div style="width:100%;float:left">
      <v-btn color="primary" style="width:100%;margin:0 auto;" @click="submit"
        >COMPLAINT</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transactionid: "",
      remark: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    }
  },
  watch: {
    clear() {
      this.cleardata();
    }
  },
  methods: {
    cleardata() {
      let vm = this;
      vm.operator = "";
      vm.remark = "";
      vm.transactionid = "";
    },
    submit() {
      let vm = this;
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn"
        });
      if (vm.transactionid == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Transaction ID Required",
          type: "error"
        });
      let params =
        "?Mob=" +
        vm.user.phone +
        "&message=com+" +
        vm.transactionid +
        "+" +
        vm.user.pin +
        "+" +
        vm.remark +
        "&source=ANDROID";
      let data = {
        "Mobile No or Recharge ID": vm.transactionid,
        Remark: vm.remark
      };
      vm.$parent.data = data;
      vm.$parent.params = params.toString();
      vm.$parent.preview = true;
    }
  }
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}
.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
