<template>
  <div>
    <v-tabs
      next-icon="arrow_forward_ios"
      prev-icon="arrow_back_ios"
      show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        class="primary--text"
        v-for="(tab, i) in tabs"
        :key="i + 'tab'"
        @click="way = tab.way"
      >
        <img :src="tab.img" :width="tab.width" />
        &nbsp; &nbsp;{{ tab.name }}
      </v-tab>
    </v-tabs>
    <Progress v-if="progress" />
    <Preview v-if="preview" :data="data" :params="params" />
    <Message v-if="message" :res="response" />
    <Recharge v-show="way == 'recharge'" />
    <Refund v-show="way == 'refund'" />
    <!-- <DMRinquiry v-show="way == 'dmrinquiry'" /> -->
    <inquiry v-show="way == 'inquiry'" />
  </div>
</template>

<script>
import Progress from "../components/Progress.vue";
import Preview from "../components/Preview.vue";
import Message from "../components/Message.vue";
import Recharge from "../recharge/Recharge.vue";
import Refund from "../recharge/Refund.vue";
import Inquiry from "../recharge/Inquiry.vue";
import DMRinquiry from "../recharge/DMRinquiry.vue";

export default {
  components: {
    Progress,
    Preview,
    Message,
    Recharge,
    Refund,
    Inquiry,
    DMRinquiry
  },
  data() {
    return {
      way: "inquiry",
      data: [],
      progress: false,
      preview: false,
      message: false,
      response: "",
      check: true,
      params: "",
      type: "DW",
      tabs: [
     
        {
          name: "Inquiry",
          img: require("../assets/adduser.png"),
          way: "inquiry",
          width: 25
        },
       
        {
          name: "Transaction",
          img: require("../assets/phone.png"),
          way: "recharge",
          width: 25
        },
        {
          name: "Refund Transaction",
          img: require("../assets/refund.png"),
          way: "refund",
          width: 25
        }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  mounted() {
    let height = window.innerHeight - 210;
    let v_table = document.getElementsByClassName("v-table__overflow");
    for (let i = 0; i < v_table.length; i++) {
      v_table[i].style.height = height + "px";
    }
  }
};
</script>
